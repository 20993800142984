import React, { Fragment, useEffect } from "react";
import { useRef } from "react";
import { useTranslator } from "src/context/storeTranslator";
import HeaderAndDesc from "./HeaderAndDesc";

var arabicLetter = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
var engLetter = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

function TextArea({
  header,
  description,
  placeholder,
  value,
  onChange,
  onEnter,
  max = 300,
}) {
  const [lang] = useTranslator();
  const ref = useRef();
  useEffect(() => {
    ref.current.focus();
  }, []);
  return (
    <Fragment>
      <HeaderAndDesc header={header} description={description} />
      <div className="w-full px-5 lg:px-0">
        <input
          lang={lang}
          ref={ref}
          min={0}
          type={"number"}
          className="border-2 focus:outline-none p-3 focus:border-theme-primary rounded w-full lg:text-center    bg-white "
          placeholder={"0"}
          value={value}
          // pattern="[0-9]*"
          inputmode="decimal"
          onKeyDown={(e) => {
            if (e.key === "Enter") onEnter();
          }}
          onKeyUp={(e) => {
            var insideText = e.key;
            var index = arabicLetter.indexOf(insideText);

            if (index >= 0) {
              let convertToEn = engLetter[index];
              const result = parseInt(value + "" + convertToEn);
              if (result > max) return onChange(max);
              onChange(result);
            }
          }}
          onChange={(v) => {
            let result = parseInt(v.target.value + "");
            if (result > max) return onChange(max);

            onChange(result);
          }}
        />
      </div>
    </Fragment>
  );
}

export default TextArea;
