import ChooseForm from "./ChooseForm";
import MultipleChoose from "./MultipleChoose";
import SliderRange from "./SliderRange";
import BooleanForm from "./BooleanForm";
import TextArea from "./TextArea";
import Numeric from "./Numberic";
export const SurveyComponent = {
  ChooseForm,
  MultipleChoose,
  SliderRange,
  BooleanForm,
  TextArea,
  Numeric,
};
