import React from "react";
import { P } from "./Text";
import Logo from "../../assets/icons/logo.svg";
import useSurveyLabel from "src/hooks/useSurveyLabel";
import { useLang } from "src/hooks/useLang";
function PowerBy({ children, center, forceLight }) {
  const label = useSurveyLabel();
  const { isEn } = useLang();
  return (
    <div
      className={`w-full border-t-[2px] ${
        forceLight
          ? "bg-theme-backgroundColor"
          : "dark:border-dark-backgroundColorThird bg-theme-backgroundColorSecondary dark:bg-dark-backgroundColorSecondary"
      }  p-3  flex  ${
        center ? "justify-center " : "justify-between"
      } items-center `}
    >
      <div className={`flex items-center `}>
        {/* <img alt="logo-power-by" src={Logo} className="w-6 h-6  " /> */}
        <div className="w-2" />
        <P className={" flex  text-sm text-gray-500"}>
          {isEn ? (
            <span>
              All rights reserved. <b>Labayh</b> © {new Date().getFullYear()}
            </span>
          ) : (
            <span>
              جميع الحقوق محفوظة. <b>لبيه</b> © {new Date().getFullYear()}
            </span>
          )}
          {/* {label["copyright"]} <div className="w-2" /> */}
          {/* <P className={"text-theme-logo  font-bold "}>{label["labyah"]}</P> */}
        </P>
      </div>
      {children}
    </div>
  );
}

export default PowerBy;
