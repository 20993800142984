import React, { useState } from "react";
import { TYPE_QUESTION, TYPE_QUESTION_DATA } from "src/constants/TOPIC_SURVEY";
import Menu from "../atoms/Menu";
import TextField from "../atoms/TextField";
import TextArea from "../atoms/TextArea";
import { LANG } from "src/constants/LANG";
import { useEffect } from "react";
import Button from "../atoms/Button";
import { P } from "../atoms/Text";
import { FaTrash } from "react-icons/fa";

const TYPE_RANGE = {
  MIN_MAX: "MIN_MAX",
  OPTIONS: "OPTIONS",
};
function BuilderSurvey() {
  const [lng, setLng] = useState("en");
  const [header, setHeader] = useState({ ar: "", en: "" });
  const [title, setTitle] = useState({ ar: "", en: "" });
  const [desc, setDesc] = useState({ ar: "", en: "" });
  const [type, setType] = useState(TYPE_QUESTION.TEXT_AREA);
  const [props, setProps] = useState({});
  const [typeRange] = useState(TYPE_RANGE.MIN_MAX);

  useEffect(() => {
    let result = {};
    if (type === TYPE_QUESTION.BOOLEAN) result = {};
    else if (type === TYPE_QUESTION.TEXT_AREA)
      result = { placeholder: { en: "", ar: "" } };
    else if (type === TYPE_QUESTION.CHOOSE_FORM) result = { options: [] };
    else if (type === TYPE_QUESTION.MULTIPLE_CHOOSE) result = { options: [] };
    else if (type === TYPE_QUESTION.RANGE) {
      if (typeRange === TYPE_RANGE.MIN_MAX)
        result = { min: 0, max: 7, step: 1 };
      else if (typeRange === TYPE_RANGE.OPTIONS) result = { options: [] };
    } else if (type === TYPE_QUESTION.NUMERIC)
      result = { placeholder: { en: "", ar: "" } };

    setProps({ ...result });
  }, [type, typeRange]);
  function onPrint() {
    const send = { title, type, props: { ...props, header, desc } };
    const jsonFile = JSON.stringify(send, undefined, 3);

    navigator.clipboard.writeText(jsonFile);
  }

  return (
    <div dir={"rtl"} className="p-3 flex flex-col items-center">
      <div className="border bg-white p-3 w-[80vw] ">
        <p
          className={"font-bold text-xl my-2 text-right w-full  border-b pb-3 "}
        >
          معلومات الأساسية
        </p>
        <Menu label={`اللغة`} value={lng} onChange={setLng} data={LANG} />
        <TextField
          label={`عنوان الخطوة`}
          value={title[lng]}
          onChange={(v) => setTitle({ ...title, [lng]: v })}
        />
        <TextField
          label={`عنوان السؤال`}
          value={header[lng]}
          onChange={(v) => setHeader({ ...header, [lng]: v })}
        />
        <TextArea
          label={`وصف السؤال`}
          value={desc[lng]}
          onChange={(v) => setDesc({ ...desc, [lng]: v })}
        />
        <Menu
          label={`نوع السؤال`}
          value={type}
          onChange={setType}
          data={TYPE_QUESTION_DATA}
        />
        <p
          className={
            "font-bold text-xl mt-5 mb-2 text-right w-full border-b pb-3 "
          }
        >
          خصائص السؤال
        </p>
        {Object.keys(props).map((key) => {
          const value = props[key];
          return (
            <div className="my-5 w-full">
              {key === "placeholder" && (
                <TextField
                  label={key}
                  value={value[lng]}
                  onChange={(v) =>
                    setProps({ ...props, [key]: { ...value, [lng]: v } })
                  }
                />
              )}
              {key === "min" && (
                <TextField
                  type={"number"}
                  label={key}
                  value={value[lng]}
                  onChange={(v) => setProps({ ...props, [key]: v })}
                />
              )}
              {key === "max" && (
                <TextField
                  type={"number"}
                  label={key}
                  value={value[lng]}
                  onChange={(v) => setProps({ ...props, [key]: v })}
                />
              )}
              {key === "step" && (
                <TextField
                  type={"number"}
                  label={key}
                  value={value[lng]}
                  onChange={(v) => setProps({ ...props, [key]: v })}
                />
              )}
              {key === "options" && (
                <OptionsForm
                  lng={lng}
                  data={value}
                  onChange={(v) => {
                    setProps({ ...props, [key]: [...v] });
                  }}
                />
              )}
            </div>
          );
        })}
        <Button onClick={onPrint}>حفظها</Button>
      </div>
    </div>
  );
}
const OptionsForm = ({ data = [], onChange, lng }) => {
  const [label, setLabel] = useState({ ar: "", en: "" });
  const [value, setValue] = useState("");

  return (
    <div className="flex flex-col">
      <P className={"text-right"}>الخيارات المتاحة</P>
      <div className="flex items-end justify-between border p-2 rounded">
        <div>
          <TextField
            label={"عنوان الإجابة بالعربي"}
            value={label["ar"]}
            onChange={(v) => setLabel({ ...label, ar: v })}
            className={"my-3"}
          />
          <TextField
            label={"عنوان الإجابة بالإنجليزي"}
            value={label["en"]}
            onChange={(v) => setLabel({ ...label, en: v })}
            className={"my-3"}
          />
          <TextField
            label={"قيمة الإجابة"}
            value={value}
            onChange={setValue}
            className={"my-3"}
          />
        </div>
        <Button
          onClick={() => {
            const send = [...data, { value, label }];

            onChange(send);

            setValue("");
            setLabel({ en: "", ar: "" });
          }}
        >
          إضافة
        </Button>
      </div>
      {data.map((item, i) => (
        <OptionForm
          key={i}
          data={item}
          lng={lng}
          index={i}
          onRemove={() => {
            const send = data.filter((_, index) => i !== index);
            onChange(send);
          }}
        />
      ))}
    </div>
  );
};
function OptionForm({ data, lng, onRemove, index }) {
  return (
    <div
      className={`w-full shadow border mt-2 lg:w-[600px] flex p-1 rounded-xl transition-all duration-200 mb-3 items-center justify-between bg-white  hover:bg-gray-200 cursor-pointer  ${"border-2 border-gray-200 border-opacity-70"}`}
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <div
            className={`h-10 w-10 flex-shrink-0 transition-all duration-100 flex justify-center items-center rounded-xl border `}
          >
            <P className={"font-bold"}>{index}</P>
          </div>
          <div className="w-3" />
          <P>{data.label[lng]}</P>
        </div>
        <div>
          <FaTrash onClick={onRemove} />
        </div>
      </div>
    </div>
  );
}
export default BuilderSurvey;
