import React from "react";
import { useTrackerSurvey } from "src/context/trackSurvey";
import MainButtonSurvey from "../atoms/MainButtonSurvey";
import StepItem from "../atoms/StepItem";

function TopicSurvey({ data, target, sm }) {
  const url = window.location.pathname;
  const select = url.split("/")[url.split("/").length - 1];
  const isSelected = data._id === select;
  const { step } = useTrackerSurvey();

  return (
    <div className="mb-2">
      <MainButtonSurvey data={data} target={target} sm={sm}></MainButtonSurvey>
      {/* {isSelected && ( */}
      <div
        className={`overflow-hidden transition-all duration-500 `}
        style={{
          height: sm
            ? "auto"
            : isSelected
            ? `${data.steps.length * 26}px`
            : "0px",
          // transition: "max-height 3s ease-in",
        }}
      >
        {data.steps.map((item, i) => (
          <StepItem
            key={item._id}
            data={item}
            index={i}
            select={data.steps[step]}
            sectionId={data._id}
            sm={sm}
          />
        ))}
      </div>
      {/* )} */}
    </div>
  );
}

export default TopicSurvey;
