import React from "react";
import { useTranslator } from "src/context/storeTranslator";
import { H1, P } from "../atoms/Text";

function HeaderAndDesc({ header, description }) {
  const [translate] = useTranslator();
  return (
    <div className="w-full flex flex-col lg:justify-center lg:items-center mb-4">
      <H1 className={"px-5  lg:text-center leading-relaxed lg:leading-[50px]"}>
        {header[translate]}
      </H1>
      <div className="h-1" />
      <P
        className={
          " lg:w-full mt-2 text-sm lg:text-md px-5 leading-relaxed  lg:text-center"
        }
      >
        {description[translate]}
      </P>
    </div>
  );
}

export default HeaderAndDesc;
