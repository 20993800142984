import { useContext, useEffect, useState } from "react";
import { createContext } from "react";
import { useHistory } from "react-router-dom";
import { TOPIC_INFO, TYPE_QUESTION } from "src/constants/TOPIC_SURVEY";
import { getCompanyNameFromSurveyURL } from "src/helper/navigation";
import { getQuestionSection } from "src/helper/surveySections";
import labahMainAPI from "../api/labahMainAPI";
import labayhAPI from "../api/labayhAPI";
import SURVEY_QUESTIONS from "src/constants/QUESTIONS_LIST";

export const SurveyProvider = createContext({
  answer: {},
  setAnswers: () => {},
  data: [],
  loading: false,
  id: "",
  onRefresh: () => {},
  companyInfo: {
    logo: "",
    name: "",
  },
  onSubmitSurvey: async () => {},
});

const ANSWER_KEY = "LABAYH_V3";

export const useSurvey = () => useContext(SurveyProvider);

export function restartAnsweredSaved() {
  localStorage.setItem(ANSWER_KEY, JSON.stringify({}));
}

export function useSurveyState() {
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [_id, set_id] = useState("");
  const [data, setData] = useState([]);
  const [companyInfo, setCompanyInfo] = useState({ logo: "", name: "" });
  const history = useHistory();

  function getSavedAnswers() {
    const result = JSON.parse(localStorage.getItem(ANSWER_KEY));
    if (!result) return false;
    if (Object.keys(result).length === 0) return false;

    const { expire, value } = result;
    const today = new Date();
    const expireDate = new Date(expire);

    if (expireDate.getTime() < today.getTime()) return false;

    return value;
  }
  function savedAnswered(answers) {
    const expire = new Date();

    expire.setDate(expire.getDate() + 30);
    const send = { expire, value: answers };

    localStorage.setItem(ANSWER_KEY, JSON.stringify(send));
  }

  async function getSurveyAPI() {
    try {
      const companySlug = getCompanyNameFromSurveyURL();
      const { data: responseCompany } = await labayhAPI.get(
        `/api/company-admin/get-logo/${companySlug}`,
        {}
      );

      setCompanyInfo({
        logo: `${process.env.REACT_APP_ASSETS}/${responseCompany.logo}`,
        name: responseCompany.name,
      });
      const { data: surveyInfo } = await labahMainAPI.get(
        "/survey/active-labayh-pro-survey",
        {}
      );

      let { data: survey } = SURVEY_QUESTIONS;

    
      const sections = survey.sections.map((item, i) => ({
        ...item,
        ...TOPIC_INFO[i],
      }));
      setData(sections);
      set_id(survey._id);
    } catch (error) {
      console.log({ error });
      if (error.response) {
        if (error.response?.status === 404) history.push("/survey/404");
        console.log({ error: error.response });
      }
    }
  }
  async function init() {
    try {
      setLoading(true);

      await getSurveyAPI();
    } catch (error) {
      console.log({ error });
      console.log({ error: error.response });
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    init();
  }, []);

  function onChangeAnswer(stepId, value) {
    let newAnswer = answers;
    newAnswer[stepId] = value;

    setAnswers({ ...newAnswer });
  }

  useEffect(() => {
    if (data.length > 0 && Object.keys(answers).length === 0) {
      const savedAnswered = getSavedAnswers();
      let answerResult = data.reduce((acc, current, indexSection) => {
        current.steps.forEach(
          (item, indexQuestion) =>
            (acc[item._id] =
              item.type === TYPE_QUESTION.RANGE
                ? [0]
                : item.type === TYPE_QUESTION.MULTIPLE_CHOOSE
                ? []
                : "")
        );
        return acc;
      }, {});

      if (savedAnswered) {
        const TOTAL_ANSWER = Object.keys(answerResult).length;
        const TOTAL_ANSWERED_SAVED = Object.keys(savedAnswered).length;

        if (TOTAL_ANSWERED_SAVED === TOTAL_ANSWER) {
          setAnswers({ ...savedAnswered });
          return;
        }
      }

      setAnswers({ ...answerResult });
    }
  }, [data]);


  async function onSubmitSurvey() {
    const name = getCompanyNameFromSurveyURL();
    const response = await labayhAPI.get(`/api/surveys/${name}`);
    const token = response.data.token;
    const companyId = response.data.id;

    let send = {
      id: companyId,
      token,
    };
    send.answers = Object.keys(answers).map((key) => {
      let value = answers[key];
      let type = getType(value);
      if (Array.isArray(value)) value = value[0];
      return {
        id: key,
        value: value,
        typeOfQuestion: type,
        section: getQuestionSection(key),
      };
    });

    await labayhAPI.post('/api/surveys/', send);

    history.push(`/survey/${name}/complete-survey`);
    restartAnsweredSaved();
  }

  function getType(value) {
    if (typeof value === "string") {
      return "text";
    } else if (typeof value === "number") {
      return "number";
    } else {
      return "array";
    }
  }

  useEffect(() => {
    if (typeof answers === "object" && Object.keys(answers).length > 0)
      savedAnswered(answers);
  }, [answers]);

  return [
    answers,
    onChangeAnswer,
    data,
    loading,
    _id,
    getSurveyAPI,
    companyInfo,
    onSubmitSurvey,
  ];
}

export function convertAnswerToBoolean(v) {
  let result = true;

  if ([""].includes(v)) result = false;
  else if (Array.isArray(v))
    if (v.length === 0) result = false;
    else if (v.length > 0) if (v[0] === 0) result = false;

  return result;
}

export default useSurvey;
