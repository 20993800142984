import React, { useEffect, useRef } from "react";
import useSurveyLabel from "src/hooks/useSurveyLabel";
import HeaderAndDesc from "./HeaderAndDesc";

function TextArea({
  header,
  description,

  value,
  onChange,
  onEnter,
}) {
  const label = useSurveyLabel();
  const ref = useRef();
  useEffect(() => {
    ref.current.focus();
  }, []);
  return (
    <div>
      <HeaderAndDesc header={header} description={description} />
      <div className="w-full px-5 lg:px-0">
        <textarea
          ref={ref}
          // rows={5}
          className="border focus:outline-none p-3 focus:border-theme-primary rounded   w-full   "
          placeholder={label["write_here"]}
          value={value}
          onChange={(v) => onChange(v.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") onEnter();
          }}
        />
      </div>
    </div>
  );
}

export default TextArea;
