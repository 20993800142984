import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useSurvey from "src/hooks/useSurvey";
import SURVEY_BG from "../../assets/SurveyIntro.png";
import PowerBy from "../atoms/PowerBy";
import HeaderSurveyIntro from "../molecules/HeaderSurveyIntro";
import SurveySection, {
  SurveySectionSkelton,
} from "../molecules/SurveySection";

function IntroSection() {
  const { data, onRefresh, loading } = useSurvey();
  const history = useHistory();

  const [, , , idSection] = window.location.pathname.split("/");

  useEffect(() => {
    onRefresh();
  }, []);
  const sectionTarget = useCallback(() => {
    if (data.length > 0) {
      const index = data.map((item) => item._id).indexOf(idSection);
      if (index < 0) return history.push("/survey/404");

      return data[index];
    } else return false;
  }, [data])();

  return (
    <div className="flex items-center flex-col w-screen h-screen">
      <img
        src={SURVEY_BG}
        alt="surveyIntro"
        className="h-full w-full absolute  object-cover top-0 "
      />
      <div className="flex-1 flex flex-col z-10 w-[90%] lg:w-[50%] pt-3 ">
        <HeaderSurveyIntro />
        <div className="flex-1 mt-3">
          {sectionTarget && (
            <SurveySection full data={sectionTarget} index={0} />
          )}
          {loading && !sectionTarget && <SurveySectionSkelton full />}
        </div>
        <PowerBy forceLight />
      </div>
    </div>
  );
}

export default IntroSection;
