import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { HelmetProvider } from "react-helmet-async";

import { Provider } from "react-redux";
import { Provider as LabayhAdminProvider } from "./context/LabayhAdmin";
import { Provider as UserProvider } from "./context/LabayhUser";
import store from "./store";
import { init as initFullStory } from "@fullstory/browser";
import {
  ArcElement,
  Title,
  Tooltip,
  Chart,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";

// Chart.register(BarElement, Title, Tooltip, CategoryScale, LinearScale);
Chart.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  PointElement,
  LineElement,
  Filler
);

initFullStory({ orgId: process.env.REACT_APP_STORY_KEY });

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <UserProvider>
        <LabayhAdminProvider>
          <App />
        </LabayhAdminProvider>
      </UserProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
