import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useTranslator } from "src/context/storeTranslator";
import useSurveyLabel from "src/hooks/useSurveyLabel";
import { P } from "../atoms/Text";
import HeaderAndDesc from "./HeaderAndDesc";

function MultipleChoose({
  options = [],
  header,
  description,
  value = [],
  onChange,
}) {
  const label = useSurveyLabel();
  return (
    <div className="w-full  lg:w-full relative flex flex-col  ">
      <HeaderAndDesc header={header} description={description} />

      <div className="px-5">
        <div className={`flex  flex-col`}>
          {options.map((item, index) => (
            <OptionForm
              onSelect={onChange}
              value={value}
              data={item}
              key={item.value}
              index={index}
            />
          ))}
        </div>

        <div className="w-full flex  justify-between text-sm py-4 ">
          <P className={"text-theme-primary font-bold"}>
            {/* {value.map((_, i) => i + 1).join(" & ")} {label["selected"]} */}
          </P>
          <P className={"text-gray-400"}>
            {options.length} {label["options"]}{" "}
          </P>
        </div>
      </div>
    </div>
  );
}

function OptionForm({ data, value, onSelect, index }) {
  const [translate] = useTranslator();
  const isSelected = value.includes(data);
  return (
    <div
      onClick={() =>
        isSelected
          ? onSelect([...value.filter((d) => d !== data)])
          : onSelect([...value, data])
      }
      className={`w-full  flex p-1 rounded-xl transition-all duration-200 mb-3 items-center justify-between bg-white  hover:bg-gray-200 cursor-pointer  ${
        isSelected
          ? "border-theme-primary border-2  shadow-theme-primary "
          : "border-2 border-gray-200 border-opacity-70"
      }`}
    >
      <div className="flex items-center">
        <div
          className={`h-10 w-10 flex-shrink-0 transition-all duration-100 flex justify-center items-center rounded-xl border ${
            isSelected && "bg-theme-primary text-white"
          }`}
        >
          <P className={"font-bold"}>{index + 1}</P>
        </div>
        <div className="w-3" />
        <P>{data.label[translate]}</P>
      </div>

      <div className="flex w-10 justify-center items-center">
        {isSelected && <FaCheckCircle className="text-theme-logo" />}
      </div>
    </div>
  );
}
export default MultipleChoose;
