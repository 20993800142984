import React, { Fragment } from "react";
import { Row } from "react-bootstrap";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useTranslator } from "src/context/storeTranslator";
import { getCompanyNameFromSurveyURL } from "src/helper/navigation";
import useSurvey, { convertAnswerToBoolean } from "src/hooks/useSurvey";
import useSurveyLabel from "src/hooks/useSurveyLabel";
import { THIS_IS_OPTIONAL } from "src/hooks/useSurveyTrackingDetails";
import Button from "../atoms/Button";

import { P } from "../atoms/Text";

function SurveySection({ data, index, full }) {
  const companyName = getCompanyNameFromSurveyURL();
  const GO_TO = `/survey/${companyName}/${data._id}`;
  const [lng] = useTranslator();
  const label = useSurveyLabel();
  const { answer } = useSurvey();
  const history = useHistory();
  const description = data?.description[lng] || "";
  const textAlign = lng === "en" ? "text-left" : "text-right";
  const countAnswer = data.steps.reduce((acc, current) => {
    if (THIS_IS_OPTIONAL.includes(current._id)) return acc;

    const result = convertAnswerToBoolean(answer[current._id]);
    return (acc += result ? 1 : 0);
  }, 0);
  const progress =
    (countAnswer /
      data.steps.filter((d) => !THIS_IS_OPTIONAL.includes(d._id)).length) *
    100;
  const isCompleted = progress === 100;
  const isMoreThanZero = progress > 0;

  return (
    <div
      onClick={() => history.push(GO_TO)}
      id={"section" + index}
      className={`flex pd-section ${
        isCompleted ? "" : "section-withHover"
      } transition-colors  duration-200 ${
        full ? "flex-col" : "lg:flex-col"
      } items-start  bg-opacity-80 hover:bg-opacity-100   cursor-pointer rounded-xl lg:rounded-custom  text-white  relative  survey_section`}
      style={{
        backdropFilter: "blur(4px)",
        // height: "auto",
      }}
    >
      <div
        className={` items-center justify-between  ${
          full ? "flex w-full" : "lg:w-full block lg:flex"
        } `}
      >
        <div
          className={`w-16  h-16 flex-shrink-0 icon-section rounded-xl lg:rounded-xl flex justify-center items-center  ${
            full ? "" : "lg:mb-3"
          }`}
        >
          <img
            alt="section-logo"
            src={data.iconURL}
            className={`iconSurveyIntro  w-10`}
          />
        </div>
        {isMoreThanZero && (
          <div className={` ${full ? "" : "hidden lg:block"}`}>
            <CompleteCircle value={progress.toFixed(0)} />
          </div>
        )}
      </div>
      <div className="w-5 lg:hidden" />
      <div
        className={`flex items-start ${
          full ? "h-[400px]  lg:pr-0" : "h-16"
        }   justify-between  lg:h-auto flex-col    flex-1 w-full `}
      >
        {full && <div className="h-5 lg:h-0 w-full  " />}
        <div className={`${full ? "" : "lg:h-28"} flex flex-col  `}>
          <div className="w-16" />
          <div className={`flex items-center `}>
            <P className={`text-xl  lg:mt-3 mb-1  ` + textAlign}>
              {data.title[lng]}
            </P>
            <div className="w-2" />
            {/* {isCompleted && (
              <Row
                className={
                  "  text-xs py-1 px-2 rounded-full text-green-200 border-green-200 border-[1px] ml-3 items-center "
                }
              >
                <P style={{ fontSize: "2px" }}>مكتمل</P>
                <div className="w-2" />
                <FaCheckCircle />
              </Row>
            )} */}
          </div>

          <P
            className={`${
              !full ? "hidden " : "lg:text-xl"
            } lg:block opacity-70 mt-2 ${textAlign}`}
          >
            {/* {full
              ? description
              : description.length > 120
              ? description.substr(0, 120) +
                "..." +
                (lng === "ar" ? "الخ" : "ect")
              : description} */}

            {description}
          </P>
        </div>
        <div className="h-16" />
        <div className="w-full flex justify-end">
          {full && <Button secondary>{label["start_from_here"]}</Button>}
        </div>

        <div
          className={`flex flex-col lg:flex-row lg:justify-between px-3 lg:mt-3  w-full ${
            lng === "ar" ? "justify-start items-start  lg:flex-row-reverse" : ""
          }`}
        >
          <Row
            className={
              "text-white text-sm opacity-60 lg:pl-4 whitespace-pre-wrap"
            }
          >
            <P>{data.steps.length}</P>

            <div className="w-3" />
            <P>
              {data.steps.length < 11
                ? label["questions"]
                : label["question"].replace("ال", "")}
            </P>
          </Row>
          <Row>
            <P className={"text-white opacity-60 text-sm"}>
              {label["duration"]} :
            </P>
            <div className="w-3" />
            <P className={"text-white opacity-60 text-sm"}>
              {data.duration[lng]}
            </P>
          </Row>
        </div>
      </div>
      <div
        className={`     w-full flex justify-start   absolute top-0 right-0  p-2`}
      >
        <div className="w-full h-full relative">
          <div
            className={` text-xs absolute ${
              lng === "en" ? "right-0" : "left-0"
            }  `}
          >
            {!full && (
              <div
                className={`${
                  isMoreThanZero ? "w-12 h-12" : "w-7 h-7 border rounded-xl"
                }   flex   lg:hidden justify-center items-center`}
              >
                {isMoreThanZero ? (
                  <Fragment>
                    <CompleteCircle sm value={progress.toFixed(0)} />
                  </Fragment>
                ) : lng === "en" ? (
                  <BsChevronRight />
                ) : (
                  <BsChevronLeft />
                )}
              </div>
            )}

            {/* <CompleteCircle /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
const CompleteCircle = ({ value, sm }) => {
  return (
    <div className="w-16 h-full 16   relative">
      {!sm && parseInt(value) === 100 && (
        <div
          className="w-7 h-7   absolute -left-1 top-0 flex justify-center items-center"
          // style={{ backgroundColor: "#4650B9" }}
        >
          <FaCheck />
        </div>
      )}
      <CircularProgressbar
        value={(!sm && parseInt(value)) === 100 ? 70 : value}
        text={value}
        strokeWidth={8}
        styles={buildStyles({
          trailColor: "#4650B9",
          pathColor: "#F8E33F",
          textColor: "#fff",
          textSize: 25,
        })}
      />
    </div>
  );
};

export function SurveySectionSkelton({ full }) {
  return (
    <div
      className={`w-full ${
        full ? "h-[500px]" : "h-[450px]"
      }  survey_section rounded-xl  p-4 flex flex-col`}
    >
      <div className=" h-20 w-20 rounded-xl icon-section animate-pulse " />
      <div className="w-72 h-8 rounded-full icon-section mt-4 bg animate-pulse" />
      <div className="w-[90%] h-5 rounded-full icon-section  bg animate-pulse mt-10" />
      <div className="w-full h-5 rounded-full icon-section  bg animate-pulse mt-2" />
      <div className="w-96 h-5 rounded-full icon-section  bg animate-pulse mt-2" />
      <div className="flex-1" />
      <div className="flex justify-between items-center">
        <div className="w-32 h-4 rounded-full icon-section  bg animate-pulse " />
        <div className="w-32 h-4 rounded-full icon-section  bg animate-pulse " />
      </div>
    </div>
  );
}
export default SurveySection;
