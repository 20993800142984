import { useEffect, useState } from "react";

export function useStateLocal(defaultValue, nameKey) {
  const [state, setState] = useState(defaultValue);
  useEffect(() => {
    let newValue = localStorage.getItem(nameKey + "");

    if (typeof defaultValue === "object") {
      localStorage.getItem(JSON.stringify(defaultValue));
    } else if (typeof newValue === "string") {
      setState(newValue);
    }
  }, []);

  function onChangeState(v) {
    try {
      if (typeof v === "object") {
        let value = JSON.stringify(v);

        localStorage.setItem(value, v);
      } else localStorage.setItem(nameKey, v);
      setState(v);
    } catch (error) {
      console.log({ error });
    }
  }
  return [state, onChangeState];
}
