import React, { Fragment, useState } from "react";
import { P } from "./Text";
import { useEffect } from "react";

export default function ProgressBar({
  value,
  color = "#35D1B5",
  label,
  rounded,
  className,
  reverse,
  forceLight,
}) {

const [mounted,setMounted] = useState(false);

useEffect(() => {
  setTimeout(() => {
    setMounted(true);
  }, 300);
  return () => {
    setMounted(false)
  }
},[])

  return (
    <div className={`w-full ${className}`}>
      {label && (
        <Fragment>
          <P className={"font-bold mb-2 text-sm"}>{label}</P>
          <div className="h-1" />
        </Fragment>
      )}
      <div
        className={`w-full bg-gray-100 ${
          forceLight ? "" : "dark:bg-dark-backgroundColor"
        }  flex ${reverse && "justify-end"}  h-2.5 ${
          rounded && "rounded-full"
        }`}
      >
       <div 
          className={` ${
            rounded && "rounded-full"
          } h-2.5 duration-700 transition-all`}
          style={{ width: mounted? `${value}%`:0, backgroundColor: color }}
        ></div>
      </div>
    </div>
  );
}
