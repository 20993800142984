import React, { Fragment, useEffect, useState } from "react";
import { useTranslator } from "src/context/storeTranslator";
import { P } from "../atoms/Text";
import HeaderAndDesc from "./HeaderAndDesc";
function SliderRange({
  min = 0,
  max = 100,
  value = [0],
  onChange = () => {},
  step = 1,
  unit = "days",
  header = "",
  description = "",
  options,
}) {
  const [translate] = useTranslator();

  const [items, setItems] = useState([]);
  useEffect(() => {
    if (Array.isArray(options) && options?.length > 0) {
      //

      let result = options.map((item) => ({
        value: item.value,
        label: item.label,
      }));

      setItems([...result]);
    } else {
      let startWith = min;
      let values = new Array(max + 1).fill(0).map((item, i) => {
        const result = {
          value: startWith,
          label: { en: startWith, ar: startWith },
        };
        startWith += step;
        return result;
      });

      setItems([...values]);
    }
  }, [options, translate]);
  const getContainValue = (v) => {
    if (value.length === 0) return false;
    else {
      return value[0].value === v;
    }
  };

  return (
    <Fragment>
      <HeaderAndDesc header={header} description={description} />
      <div className="  flex  lg:flex-nowrap flex-wrap px-5 mt-4 w-full  ">
        {items.map((d) => {
          const label = typeof d?.label === "object" ? d?.label[translate] : "";
          return (
            <div
              key={d}
              onClick={() => onChange([d])}
              className={`h-20 bg-theme-primary flex-col m-1 flex-shrink-0 border-theme-primary border-[1px] border-opacity-70 rounded-xl flex justify-center  items-center  cursor-pointer duration-200 px-2 text-center transition-all  ${
                getContainValue(d.value)
                  ? " bg-opacity-100 text-white"
                  : "hover:bg-40 bg-opacity-10 text-black"
              }`}
              style={{ width: "64px", height: "64px" }}
            >
              <P className={""}>{label}</P>
              {unit && (
                <P className={"text-xs opacity-60"} style={{ fontSize: "7px" }}>
                  {unit[translate]}
                </P>
              )}
            </div>
          );
        })}
      </div>
      {/* <div className="my-5 px-5 hidden lg:block">
        <Range
          min={min}
          max={max}
          values={value}
          onChange={onChange}
          step={step}
          rtl={translate === "ar"}
          direction={translate === "ar" ? Direction.Left : Direction.Right}
          renderMark={({ index, props }) => {
            const label = items[index] ? items[index].label[translate] : "";
            return (
              <div
                {...props}
                className="relative"
                style={{
                  ...props.style,
                  height: "26px",
                  // width: "5px",
                  // backgroundColor: index * step < value[0] ? "#548BF4" : "#ccc",
                }}
              >
                <div className="absolute -bottom-16 ">
                  <div
                    className={`h-4 w-[3px] transition-all duration-200 ${
                      value[0] >= index
                        ? "bg-theme-logo shadow-xl shadow-theme-logo"
                        : "bg-gray-300"
                    }  rounded-full mb-2`}
                  />
                  <div className="hidden lg:block">
                    <P
                      className={"whitespace-nowrap font-bold "}
                      style={{
                        fontSize: "13px",
                        transform: index === 0 ? "" : "translateX(-20px)",
                      }}
                    >
                      {index === 0
                        ? `${translate === "en" ? "no" : "لا"} ${
                            unit[translate]
                          }`
                        : `${unit[translate]} ${index}`}
                    </P>
                  </div>
                  <P className="block lg:hidden text-sm">{label}</P>
                </div>
              </div>
            );
          }}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              className={`rage-style-pc  `}
              style={{ ...props.style }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              className="border rounded-xl transform  text-sm shadow bg-white flex justify-center items-center transition-all duration-200 "
              style={{
                height: "34px",
                width: "40px",
                // transform: `scale(${isDragged ? "1.2" : "1"})`,
                // backgroundColor: isDragged ? "red" : "white",
                ...props.style,
              }}
            >
              <FaCaretLeft />
              <FaCaretRight />
            </div>
          )}
        />
      </div> */}
    </Fragment>
  );
}

export default SliderRange;
