import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTrackerSurvey } from "src/context/trackSurvey";
import { getCompanyNameFromSurveyURL } from "src/helper/navigation";
import useSurvey, { convertAnswerToBoolean } from "./useSurvey";

export const THIS_IS_OPTIONAL = ["62dffec9251aaf9405827a98"];

export const IgnoreThisQuestion = (key) => !THIS_IS_OPTIONAL.includes(key);
export function useSurveyTrackingDetails() {
  const { target, step, setTarget, setStep } = useTrackerSurvey();
  const { answer, data } = useSurvey();
  const companyName = getCompanyNameFromSurveyURL();
  const history = useHistory();

  const response = useCallback(() => {
    let result = {
      IS_FIRST_SECTION: false,
      IS_FIRST_QUESTION: false,
      IS_LAST_SECTION: false,
      IS_LAST_QUESTION: false,
    };
    if (data.length > 0) {
      let currentSectionIndex = target
        ? data.map((item) => item._id).indexOf(target)
        : 0;

      result.IS_FIRST_QUESTION = step === 0;
      result.IS_LAST_QUESTION =
        data[currentSectionIndex].steps.length - 1 === step;
      result.IS_FIRST_SECTION = data[0]._id === target;
      result.IS_LAST_SECTION = data[data.length - 1]._id === target;
    }

    return result;
  }, [data, step, target])();
  const ANSWERS_KEY = Object.keys(answer)
    .filter(IgnoreThisQuestion)
    .slice(0, 82);
  const staticOfAnswers = useCallback(() => {
    if (ANSWERS_KEY.length === 0)
      return {
        PERCENT_OF_COMPLETE: 0,
        TOTAL_ANSWERS: 0,
      };
    const TOTAL_ANSWERS = ANSWERS_KEY.reduce((acc, key) => {
      let value = answer[key];

      const valueBoolean = convertAnswerToBoolean(value);

      acc += valueBoolean ? 1 : 0;
      return acc;
    }, 0);
    const PERCENT_OF_COMPLETE = (TOTAL_ANSWERS / ANSWERS_KEY.length) * 100;
    return { PERCENT_OF_COMPLETE, TOTAL_ANSWERS };
  }, [answer])();

  function onPrevSection() {
    if (response.IS_FIRST_SECTION && response.IS_FIRST_QUESTION) return;

    const index = data.map((d) => d._id).indexOf(target);
    const sectionId = data[index - 1]._id;
    const stepLast = data[index - 1].steps?.length - 1;
    if (index > 0) {
      setStep(stepLast);

      setTarget(sectionId);
      const url = `/survey/${companyName}/${sectionId}`;
      history.push(url);
    }
  }
  function onNextSection() {
    const index = data.map((d) => d._id).indexOf(target);
    if (index - 1 === data.length) return;
    const sectionId = data[index + 1]._id;
    setStep(0);
    setTarget(data[index + 1]._id);
    const url = `/survey/${companyName}/${sectionId}`;
    history.push(url);
  }
  function isCompleteSection(_id) {
    if (!_id) return false;
    const currentSection = data.filter((d) => d._id === _id);
    if (currentSection.length === 0) return false;

    const d = currentSection[0].steps.every((item) =>
      convertAnswerToBoolean(answer[item._id])
    );

    return d;
  }

  return {
    ...response,
    ...staticOfAnswers,
    onPrevSection,
    onNextSection,
    isCompleteSection,
    COUNT_ANSWERS: ANSWERS_KEY.length,
  };
}
