import React from "react";
import { FaCheck } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useTranslator } from "src/context/storeTranslator";
import { useTrackerSurvey } from "src/context/trackSurvey";
import { getCompanyNameFromSurveyURL } from "src/helper/navigation";
import { P } from "./Text";

function MainButtonSurvey({ data, sm }) {
  const url = window.location.pathname;
  const companyName = getCompanyNameFromSurveyURL();

  const { target, setStep } = useTrackerSurvey();
  const select = url.split("/")[url.split("/").length - 1];
  const isSelected = data._id === select;

  const isGreater = target > data.target;
  const GO_TO = `/survey/${companyName}/${data._id}`;

  const history = useHistory();
  const [translate] = useTranslator();
  const { setTarget } = useTrackerSurvey();
  return (
    <div
      onClick={() => {
        setTarget(data._id);
        setStep(0);

        history.push(GO_TO);
      }}
      className={`flex justify-between items-center px-2  s ${
        sm
          ? ""
          : isSelected
          ? "bg-theme-primary text-white shadow-theme-primary shadow py-2 mb-3 rounded-xl"
          : "bg-theme-logo-light cursor-pointer hover:bg-theme-primary py-2 mb-3 hover:bg-opacity-30  transition-all duration-200 rounded-xl "
      }    `}
    >
      <div className="flex items-center">
        <div
          className={`flex justify-center items-center w-10 h-10 bg-white ${
            translate === "en" ? "mr-4" : "ml-4"
          } ${sm ? "hidden" : ""} rounded-xl `}
        >
          <img src={data.iconURL} alt="" className="w-6" />
        </div>
        <P
          className={` ${
            sm
              ? isSelected
                ? "text-black font-bold text-lg"
                : "text-gray-400 text-lg "
              : ""
          }`}
        >
          {data.title[translate]}
        </P>
      </div>
      <div
        className={`text-xs rounded-full  flex justify-center items-center  ${
          isSelected ? "bg-theme-primary-light" : "bg-white"
        }`}
      >
        {isGreater ? (
          <div className="h-6 w-6 rounded-full flex justify-center items-center bg-theme-logo text-white">
            <FaCheck />
          </div>
        ) : sm ? (
          ""
        ) : (
          <P className="w-8 h-6 justify-center items-center flex">
            {data.steps.length}
          </P>
        )}
      </div>
    </div>
  );
}
export default MainButtonSurvey;
