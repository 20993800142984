import React from "react";
import useSurveyLabel from "src/hooks/useSurveyLabel";
import PowerBy from "../atoms/PowerBy";
import { H1, P } from "../atoms/Text";
import useCompanyDetails from "src/hooks/useCompanyDetails";
import { useLang } from "src/hooks/useLang";

function Survey404() {
  const label = useSurveyLabel();
  const {supportsSurvey} = useCompanyDetails();
  const { lang } = useLang();
  const notSupportSurvey = {
    en:"Company plan doesn't support the survey",
    ar:"باقة الشركة لا يتوفر فيها الاستبيان"
  }
  return (
    <div
      className="w-screen h-screen flex flex-col  "
      style={{ backgroundColor: "#F6F8FC" }}
    >
      <div className="flex justify-center items-center flex-1 ">
        <div className="border w-96 lg:w-1/2 h-2/3 bg-white rounded-xl  px-5 flex justify-center items-center flex-col">
          <H1
            className={"text-theme-logo text-6xl mb-10"}
            style={{ fontSize: "90px" }}
          >
            404
          </H1>
          <P className={"text-center whitespace-pre-line"}>
            {!supportsSurvey? notSupportSurvey[lang] :  label["page-404"]}
          </P>
        </div>
      </div>
      <PowerBy forceLight />
    </div>
  );
}

export default Survey404;
