import CreateContext from './createContext'
import LabayhUser from '../api/labayhAPIUsers'
import axios from 'axios'
import {  FormattedMessage } from "react-intl"
import validator from 'validator'




const socketReducer = (state, action) => {
    switch (action.type) {
        case 'sign_in':
            return { ...state, }
        case 'error_message':
            return { ...state, erroeMessageUser: action.payload }
        case 'clear_error_message':
            return { ...state, erroeMessageUser: '' }
        case 'set_phonNumber':
            return { ...state, phomeNumber: action.payload }
        case 'set_labayhUserId':
            return { ...state, labayhUserId: action.payload }
        default:
            return state
    }
}
const VerviyWithLabayAuth = dispatch => async (mobileNumber, countryCode) => {
    try {
      if (!validator.isMobilePhone(`+${countryCode}${mobileNumber}`)) {
        dispatch({ type: 'set_phonNumber', payload: '' })
        dispatch({
          type: 'error_message', payload:
            <FormattedMessage id="ValidPhone" />
        })
      } else {
        const req = await axios.post(`${process.env.REACT_APP_LABAYH_20_API}/auth`, {
          "mobile": `${mobileNumber}`,
          "countryCode": `${countryCode}`,
          "authMethod": "regular",
          "userType": "patient",
          "token": "",
          "voipToken": "",
          "deviceType": "web",
          "deviceToken": ""
        })

        if (req.status === 200) {
          dispatch({
            type: 'error_message', payload:
              <FormattedMessage id="cheekWallet" />
          })
          dispatch({ type: 'set_phonNumber', payload: mobileNumber })
        }
      }

    }
    catch (error) {
        dispatch({
            type: 'error_message', payload:
                (error.response) ? error.response.data.error :
                <FormattedMessage id="error501" />

        })

    }


}

const VerviyWithOTPAuth = dispatch => async (mobileNumber, OTP, cbToNavigateToMain) => {
    let lang = await localStorage.getItem('lang')
    try{
    const req = await axios.post(`${process.env.REACT_APP_LABAYH_20_API}/verify`, {

        "otp": `${OTP}`,
        "userType": "patient",
        "deviceToken": "I am login from website",
        "agent": "mobile",
        "mobile": `${mobileNumber}`,

    }, {
        headers: {
            "Accept-Language": lang
        },
})
        dispatch({ type: 'set_labayhUserId', payload: req.data.user.id })
        cbToNavigateToMain()
    }
    catch(error) {
        dispatch({
            type: 'error_message', payload:
                (error.response) ? error.response.data.error :
                <FormattedMessage id="error501" />
        })

    }

}
const signinUser = dispatch => async (company, labayhUserId, employeeId, cbToShowRedeemBn) => {
    let lang = await localStorage.getItem('lang')

        try {
            const response = await LabayhUser.post(`/api/employee/login/${company}`, { company, employeeId, labayhUserId }
            , {
                headers: {
                    "Accept-Language": lang
                },
                })

            await localStorage.setItem('utoken', response.data.token)
            dispatch({ type: 'sign_in' })
            cbToShowRedeemBn()
        }
        catch (error) {
            dispatch({
                type: 'error_message', payload:
                    (error.response) ? error.response.data.error :
                    <FormattedMessage id="error501" />
            })
        }

}

const UserRedeem = dispatch => async () => {
    let lang = await localStorage.getItem('lang')
    try {
         await LabayhUser.post('/api/employee/redeem/', {
        }, {
            headers: {
                lang
            },
    })
        dispatch({
            type: 'error_message',
            payload:
            <FormattedMessage id="cheekLabayhWallet" />
        })
        await localStorage.removeItem('utoken')
    }
    catch (error) {
        dispatch({
            type: 'error_message', payload:
                (error.response) ? error.response.data.error :
                <FormattedMessage id="error501" />
        })
    }


}

const clearErrorMessage = dispatch => () => {
    dispatch({ type: 'clear_error_message' })
}







export const { Context, Provider } = CreateContext(
    {

        erroeMessageUser: '', phomeNumber:'',labayhUserId:''
    },
    {
        signinUser,UserRedeem,
        VerviyWithLabayAuth, VerviyWithOTPAuth,
        clearErrorMessage,

    },
    socketReducer
)
