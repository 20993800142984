import axios from 'axios'

const instance = axios.create({
    baseURL:
    // 'https://portal.labayh.net/'
    process.env.REACT_APP_COMPANY_API
})
instance.interceptors.request.use(
    async (config) => {
        const token = await localStorage.getItem('utoken')
        if(token){
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (err) => {
        return Promise.reject(err)
    }
)


export default instance



