import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useTranslator } from "src/context/storeTranslator";
import { P } from "../atoms/Text";
import HeaderAndDesc from "./HeaderAndDesc";

function ChooseForm({ header, description, options, value, onChange }) {
  return (
    <div>
      <HeaderAndDesc header={header} description={description} />
      <div className="mb-2" />
      <div className="w-full  flex flex-col lg:items-center  px-5">
        {options.map((item, i) => (
          <OptionForm
            data={item}
            value={value}
            onSelect={onChange}
            key={i}
            index={i}
          />
        ))}
      </div>
    </div>
  );
}
function OptionForm({ data, value, onSelect, index }) {
  const [translate] = useTranslator();
  const isSelected = data.value === value;
  return (
    <div
      onClick={() => onSelect(data.value)}
      className={`w-full lg:w-[600px] flex p-1 rounded-xl transition-all duration-200 mb-3 items-center justify-between bg-white  hover:bg-gray-200 cursor-pointer  ${
        isSelected
          ? "border-theme-primary border-2  shadow-theme-primary  bg-white"
          : "border-2 border-gray-200 border-opacity-70"
      }`}
    >
      <div className="flex items-center">
        <div
          className={`h-10 w-10 flex-shrink-0 transition-all duration-100 flex justify-center items-center rounded-xl border ${
            isSelected && "bg-theme-primary text-white"
          }`}
        >
          <P className={"font-bold"}>{index + 1}</P>
        </div>
        <div className="w-3" />
        <P>{data.label[translate]}</P>
      </div>

      <div className="flex w-10 justify-center items-center">
        {isSelected && <FaCheckCircle className="text-theme-logo" />}
      </div>
    </div>
  );
}
export default ChooseForm;
