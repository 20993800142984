import React from "react";
import { P } from "../atoms/Text";
import PowerBy from "../atoms/PowerBy";
import useSurveyLabel from "src/hooks/useSurveyLabel";
import { FaCheck } from "react-icons/fa";
function CompletePage() {
  const label = useSurveyLabel();
  return (
    <div
      className="w-screen h-screen flex flex-col  "
      style={{ backgroundColor: "#F6F8FC" }}
    >
      <div className="flex justify-center items-center flex-1 ">
        <div className="border w-96 lg:w-1/2 h-2/3 lg:bg-white rounded-xl  flex justify-center items-center flex-col">
          <P className={"font-bold mb-3 text-3xl "}>{label["thanks"]}</P>
          <P className={"mb-4"}>{label["thank_desc"]}</P>
          <IconCheck />
        </div>
      </div>
      <div className="hidden lg:block">
        <PowerBy forceLight />
      </div>
    </div>
  );
}
const IconCheck = () => (
  <div
    className="flex justify-center items-center w-48  h-48 bg-green-500 mt-2 rounded-full"
    style={{ backgroundColor: "#F3FAFB" }}
  >
    <div
      className="w-[80%] h-[80%] rounded-full bg-theme-logo flex justify-center items-center"
      style={{ backgroundColor: "#E0F6F4" }}
    >
      <div
        className="w-[80%] h-[80%] rounded-full  flex justify-center items-center"
        style={{ backgroundColor: "#35D1B5" }}
      >
        <FaCheck className="text-5xl text-white" />
      </div>
    </div>
  </div>
);
export default CompletePage;
