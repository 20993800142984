import axios from "axios";

const instance = axios.create({
  baseURL:
    // 'https://portal.labayh.net/'
    process.env.REACT_APP_COMPANY_API,
});
instance.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem("ctoken");
    const lang = await localStorage.getItem("lang");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;

      config.headers.lang = `${lang ? lang : "ar"}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
export const StagingPortal = instance;
export default instance;
