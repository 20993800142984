import React from "react";
import { useTranslator } from "src/context/storeTranslator";

function Row({ children, className, onClick = () => {} }) {
  const [lang] = useTranslator();
  return (
    <div
      onClick={onClick}
      className={`flex ${
        lang === "ar" ? "flex-row-reverse" : "flex-row"
      } ${className}`}
    >
      {children}
    </div>
  );
}

export default Row;
