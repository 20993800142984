import React, { Fragment, useState } from "react";

import DrawerSurvey from "../organisms/DrawerSurvey";
import { useEffect } from "react";
import HeaderSurvey from "../molecules/HeaderSurvey";
import { useHistory } from "react-router-dom";

import { useCallback } from "react";

import useSurvey, { convertAnswerToBoolean } from "src/hooks/useSurvey";
import { useTranslator } from "src/context/storeTranslator";
import LoadingSurvey from "../subPages/LoadingSurvey";

import SurveyContent from "../subPages/SurveyContent";

import { TrackSurveyStore } from "src/context/trackSurvey";

import SwitchQuestions from "../molecules/SwitchQuestions";
import PowerBy from "../atoms/PowerBy";
import SwitchLang from "../molecules/SwitchLang";
import { IgnoreThisQuestion } from "src/hooks/useSurveyTrackingDetails";

function SurveyDashboard() {
  const [target, setTarget] = useState(0);
  const [step, setStep] = useState(0);
  const {
    answer,

    data,
    loading,

    onRefresh,

    onSubmitSurvey,
  } = useSurvey();
  const [lang] = useTranslator("en", "lang");
  const [isScroll, setIsScroll] = useState(false);
  const [open, setOpen] = useState(false);
  const [isNotCompleted, setIsNotCompleted] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  // const [data, setData] = useState({});

  const history = useHistory();

  const companyName = window.location.pathname;
  let [, , name, survey_id] = companyName.split("/");
  const ANSWERS_KEY = Object.keys(answer).filter(IgnoreThisQuestion);

  const TOTAL = ANSWERS_KEY.reduce((acc, key) => {
    let value = answer[key];

    const valueBoolean = convertAnswerToBoolean(value);

    acc += valueBoolean ? 1 : 0;
    return acc;
  }, 0);

  const percent = (TOTAL / ANSWERS_KEY.length) * 100;
  const IS_ALL_COMPLETED = () => {
    if (data.length === 0) return;
    const isLasQuestion = step === data[data.length - 1].steps.length - 1;
    return percent !== 100 && isLastSection && isLasQuestion;
  };

  useEffect(() => {
    onRefresh();
  }, []);
  useEffect(() => {
    if (data.length > 0) {
      if (target === 0) {
        // const firstSection = data[0]._id;

        setTarget(survey_id);
        const url = `/survey/${name}/${survey_id}`;
        // setTarget(data[0]._id);
        history.push(url);
      } else {
        const isContain = data.some((item) => item._id === target);
        if (!isContain) history.push("/404");
      }
    }
    return () => {};
  }, [data]);

  useEffect(() => {
    if (survey_id && data.length > 0) {
      let isSurveyIdValid = data.some((item) => item._id === survey_id);
      if (!isSurveyIdValid) return history.push({ pathname: "/survey/404" });
    }
    return () => {};
  }, [data, survey_id]);

  const currentData = useCallback(
    () => data.filter((item) => item._id === target)[0],
    [target, data]
  )();

  function getIsLastQuestion() {
    if (data.length === 0) return;
    const isLastSection = target === data[data.length - 1]._id;
    const isLasQuestion = step === data[data.length - 1].steps.length - 1;

    return isLastSection && isLasQuestion;
  }
  const isLastSection = getIsLastQuestion();

  async function onSubmit() {
    try {
      setLoadingSubmit(true);
      await onSubmitSurvey();
    } catch (error) {
      console.log('error--->',{ error });
      console.log({ response: error.response });
    } finally {
      setLoadingSubmit(false);
    }
  }

  function jumpToNextSection(v) {
    let i = 0;
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (element._id === currentData._id) {
        i = index;
      }
    }
    if (i + 1 === data.length) {
      return onSubmit();
    }

    let sectionIDNext = data[i + 1]._id;
    const url = `/survey/${name}/${sectionIDNext}/intro`;

    setStep(0);
    setTarget(sectionIDNext);
    history.push(url);
  }
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      const y = e.currentTarget.scrollY;
      if (y > 5) setIsScroll(true);
      else setIsScroll(false);
    });
    // here if user suddenly refresh page
    window.onbeforeunload = function () {
      // localStorage.setItem("ANSWERED", JSON.stringify(answer));
      return "Hey, you're leaving the site. Bye!";
    };
    return () => {};
  }, []);

  const currentStep = useCallback(() => {
    if (currentData) {
      return currentData.steps[step];
    }
  }, [currentData])();
  const isFirstQ = data.length > 0 ? data[0]._id === target : "";

  return (
    <Fragment>
      <TrackSurveyStore.Provider
        value={{
          setStep,
          setTarget,
          step,
          target,
          isFirstQ,
          currentStep,
          open,
          setOpen: (v, error) => {
            if (isNotCompleted) setIsNotCompleted(false);
            setOpen(v);
            if (error){ console.log('error',error); setIsNotCompleted(true)};
          },
          isNotCompleted,
        }}
      >
        {loading && <LoadingSurvey />}
        <div
          className={`bg-theme-backgroundColorSecondary  w-screen  overflow-hidden flex ${
            lang === "ar" ? "text-right" : ""
          }`}
        >
          <DrawerSurvey />
          <div className="flex flex-col flex-1  h-screen lg:h-auto relative  ">
            <div className="flex-1  flex flex-col relative flex-shrink-0">
              <HeaderSurvey isScroll={isScroll} currentData={currentData} />
              {/* <NavHeader /> */}
              <div
                className="flex flex-col  lg:h-[70vh] pt-3  flex-shrink-0    overflow-y-auto overflow-x-hidden"
              >
                <div className="flex-1 flex flex-col   ">
                  <SurveyContent
                    topic={currentData}
                    isFirstChild={isFirstQ}
                    onNextSection={jumpToNextSection}
                    isLastSection={isLastSection}
                    loadingSubmit={loadingSubmit}
                    IS_ALL_COMPLETED={IS_ALL_COMPLETED}
                  />
                </div>
              </div>
              <div className="flex-1" />
              <div className="hidden lg:block  ">
                <PowerBy forceLight>
                  <SwitchLang />
                </PowerBy>
              </div>
            </div>
            
          </div>
        </div>
      </TrackSurveyStore.Provider>
    </Fragment>
  );
}
export default SurveyDashboard;
