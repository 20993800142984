import React, { Fragment, useEffect } from "react";
import { P } from "../atoms/Text";
import { useTranslator } from "src/context/storeTranslator";
import useSurveyLabel from "src/hooks/useSurveyLabel";

import { useTrackerSurvey } from "src/context/trackSurvey";
import ProgressBar from "../atoms/ProgressBar";
import { useSurveyTrackingDetails } from "src/hooks/useSurveyTrackingDetails";
import useSurvey from "src/hooks/useSurvey";
import { HiMenuAlt2, HiMenuAlt3 } from "react-icons/hi";
import DrawerSurveyMobile from "../organisms/DrawerSurveyMobile";

function HeaderSurvey({ currentData }) {
  const [translate] = useTranslator();
  const { companyInfo } = useSurvey();
  // const [open, setOpen] = useState();
  const label = useSurveyLabel();
  const { step, target, open, setOpen } = useTrackerSurvey();
  const { PERCENT_OF_COMPLETE } = useSurveyTrackingDetails();
  useEffect(() => {
    if (open) {
      setOpen(false);
    }
  }, [step, target]);
  return (
    <Fragment>
      <ProgressBar
        value={PERCENT_OF_COMPLETE}
        color={"#486DF6"}
        className={"block lg:hidden h-5"}
        forceLight
      />
      <div
        className="w-full  transition-all duration-300  flex justify-between  lg:rounded-b-none bg-transparent  lg:shadow-none  h-24 flex-shrink-0 items-center border-b pl-5 pr-5 "
        style={{ height: `10vh` }}
      >
        <div
          onClick={() => setOpen(true)}
          className={` w-14  h-14 rounded cursor-pointer text-4xl text-theme-primary hover:bg-gray-100 flex lg:hidden justify-center items-center transform ${
            translate === "en" ? "-translate-x-5" : " translate-x-5"
          }`}
        >
          {translate === "en" ? <HiMenuAlt2 /> : <HiMenuAlt3 />}
        </div>
        <P className="hidden lg:block text-md lg:text-xl font-bold">
          {label["staff_health"]}
          <br className="lg:hidden" /> {label["wellbeing_survey"]}
        </P>

        <div className=" hidden  lg:flex ">
          <div className="flex flex-col items-end">
            <P className="text-gray-400">{label["part"]} 1</P>
            <P className="font-bold">{currentData?.title[translate]}</P>
          </div>
          <div className="h-10 bg-gray-300 w-[1px] mx-4" />
          <div className="flex flex-col items-end">
            <P className="text-gray-400">{label["question"]}</P>
            <P className="font-bold">
              {step + 1}/{currentData?.steps?.length}
            </P>
          </div>
        </div>
        <div className="flex lg:hidden items-center ">
          <P className={"font-bold text-2xl hidden lg:block"}>
            {companyInfo.name}
          </P>
          <div className="w-3" />
          <img src={companyInfo.logo} alt="" className="h-12 " />
        </div>
      </div>
      <DrawerSurveyMobile open={open} onClose={() => setOpen(false)} />
    </Fragment>
  );
}

export default HeaderSurvey;
