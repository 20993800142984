const SURVEY_QUESTIONS = {
  data: {
    name: {
      ar: "استبيان",
      en: "Survey",
    },
    _id: "62dffec9251aafj4058278bc",
    sections: [
      {
        title: {
          ar: "الملف الشخصي",
          en: "Personal profile",
        },
        description: {
          ar: "الهدف من هذا القسم هو فهم عادات عمل الموظفين وتحديد فرص تحسين صحتهم.",
          en: "The aim of this section is to understand staff’s work habits and identify opportunities for improving their health.",
        },
        iconURL:
          "https://www.svgrepo.com/show/512708/profile-image-favorite-round-1330.svg",
        _id: "62dffec9251aaf94058278be",
        steps: [
          {
            _id: 80,
            title: {
              ar: "السؤال الاول",
              en: "1st Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما هو جنسك؟",
                en: "What is your gender? ",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ذكر",
                    en: "Male",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "انثى",
                    en: "Female",
                  },
                },
              ],
            },
          },
          {
            _id: 81,
            title: {
              ar: "السؤال الثاني",
              en: "2nd Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما هو عمرك؟",
                en: "What is your age?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "0-15",
                    en: "0-15",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "16-24",
                    en: "16-24",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "25-34",
                    en: "25-34",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "35-44",
                    en: "35-44",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "45-54",
                    en: "45-54",
                  },
                },
                {
                  value: 6,
                  label: {
                    ar: "55-64",
                    en: "55-64",
                  },
                },
                {
                  value: 7,
                  label: {
                    ar: "65-74",
                    en: "65-74",
                  },
                },
                {
                  value: 8,
                  label: {
                    ar: "75-84",
                    en: "75-84",
                  },
                },
                {
                  value: 9,
                  label: {
                    ar: "85+",
                    en: "85+",
                  },
                },
              ],
            },
          },
        ],
      },
      {
        title: {
          ar: "الصحة الحالية",
          en: "Current Health",
        },
        description: {
          ar: "الهدف من هذا القسم هو الحصول على علامة أساسيّة لصحة الموظفين الحالية مع التركيز على النشاط البدني والأكل الصحي، ويمكن استخدام هذه المعلومات في المراقبة والتقييم المستمر لبرنامج صحتك ورفاهيتك.",
          en: "The aim of this section is to gain a baseline marker for employees’ current health focusing on physical activity and healthy eating. This information can be used in the ongoing monitoring and evaluation of your health and wellbeing programme.",
        },
        iconURL: "https://www.svgrepo.com/show/347168/mental-health.svg",
        _id: "62dffec9251aaf94058278b3",
        steps: [
          {
            _id: 0,
            title: {
              ar: "السؤال الاول",
              en: "1st Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "أي من التالي يصفك في جانب النشاط البدني",
                en: "Which of the following best describes you? (Physical Activity)",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ليس لديّ اهتمام باتّباع أسلوب حياة صحي أو ممارسة النشاط البدني.",
                    en: "I am not interested in pursuing a healthy lifestyle or being physically active.",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "كنت أفكر مؤخراً في أن أكون أكثر نشاطاً.",
                    en: "I have recently been thinking about being more active.",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "أتطلع لأن أصبح أكثر نشاطاً خلال الأشهر الستة المقبلة.",
                    en: "I am looking to become more active within the next six months.",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "غيّرت سلوكي مؤخراً وأنا الآن نشيط بشكل أكثر انتظاماً عن قبل.",
                    en: "I have recently changed my behaviour and I am active on a more regularly.                                       ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "نشيط بشكل منتظم لمدة ستة أشهر على الأقل.",
                    en: "I have been regularly active for at least six months.",
                  },
                },
              ],
            },
          },
          {
            _id: 1,
            title: {
              ar: "السؤال الثاني",
              en: "2nd Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "أي من التالي يصفك في جانب الأكل الصحي؟",
                en: "Which of the following best describes you? (Healthy Eating)",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ليس لديّ اهتمام بإتّباع أسلوب حياة صحي.",
                    en: "I am not interested in pursuing a healthy lifestyle",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "كنت أفكر مؤخرًا في تغيير نظامي الغذائي",
                    en: "I have recently been thinking about changing my diet",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "أنوي تغيير سلوكي وتغيير نظامي الغذائي خلال الأشهر الستة المقبلة",
                    en: "I am intending to change my behavior and to change my diet within the next six months",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "قمت مؤخرًا بتغيير سلوكي وأنا أتبع خطة للأكل الصحي",
                    en: "I have recently changed my behavior and I am following a healthy eating plan.",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "كنت أتبع خطة للأكل الصحي لمدة ستة أشهر على الأقل",
                    en: "I have been following a healthy eating plan for at least six months.",
                  },
                },
                // {
                //   value: 6,
                //   label: {
                //     ar: "نشيط بشكل منتظم لمدة ستة أشهر على الأقل.",
                //     en: "I have special dietary needs that prevent me from changing my diet",
                //   },
                // },
              ],
            },
          },
        ],
      },
      {
        title: {
          ar: "الاحتياجات الصحية",
          en: "Health needs",
        },
        description: {
          ar: "الهدف من هذا القسم هو فهم أيّ احتياجات خاصة تتعلق بصحة الموظفين أو الظروف الصحيّة، لذلك يجب أن تتعلق الأسئلة بالاحتياجات الصحيّة الحاليّة للموظفين، وكذلك اهتمامهم بإتباع أسلوب حياة أكثر صحة، وكيف يمكن لمكان العمل أن يوفر لهم فرصاً للقيام بذلك.",
          en: "The aim of this section is to understand any special needs related to employees’ health or medical conditions. Questions therefore need to relate to employees’ current health needs, ",
        },
        iconURL: "https://www.svgrepo.com/show/322503/healing.svg",
        _id: "62dffec9251aaf94058278bc",
        steps: [
          {
            _id: 64,
            title: {
              ar: "السؤال الاول",
              en: "1st Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "كيف تصف صحتك العامة؟",
                en: "How would you describe your general health? ",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيفة جداً ",
                    en: "Very poor ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيفة ",
                    en: "Poor ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسطة ",
                    en: "Fair ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيّدة ",
                    en: "Good ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "جيّدة جداً ",
                    en: "Very good ",
                  },
                },
              ],
            },
          },
          {
            _id: 65,
            title: {
              ar: "السؤال الثاني",
              en: "2nd Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "كيف تصف كل مما يأتي عندما تكون في العمل: مستويات الطاقة",
                en: "How would you describe the following when you are at work: Energy levels",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيفة جداً ",
                    en: "Very poor ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيفة ",
                    en: "Poor ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسطة ",
                    en: "Fair ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيّدة ",
                    en: "Good ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "جيّدة جداً ",
                    en: "Very good ",
                  },
                },
              ],
            },
          },
          {
            _id: 66,
            title: {
              ar: "السؤال الثالث",
              en: "3rd Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "كيف تصف كل مما يأتي عندما تكون في العمل: المزاج",
                en: "How would you describe the following when you are at work: Mood",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيفة جداً ",
                    en: "Very poor ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيفة ",
                    en: "Poor ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسطة ",
                    en: "Fair ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيّدة ",
                    en: "Good ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "جيّدة جداً ",
                    en: "Very good ",
                  },
                },
              ],
            },
          },
          {
            _id: 67,
            title: {
              ar: "السؤال الرابع",
              en: "4th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "كيف تصف كل مما يأتي عندما تكون في العمل: التركيز",
                en: "How would you describe the following when you are at work: Concentration",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيفة جداً ",
                    en: "Very poor ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيفة ",
                    en: "Poor ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسطة ",
                    en: "Fair ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيّدة ",
                    en: "Good ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "جيّدة جداً ",
                    en: "Very good ",
                  },
                },
              ],
            },
          },
          {
            _id: 68,
            title: {
              ar: "السؤال الخامس",
              en: "5th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "كيف تصف كل مما يأتي عندما تكون في العمل: مستويات التوتر",
                en: "How would you describe the following when you are at work: Stress levels",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيفة جداً ",
                    en: "Very poor ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيفة ",
                    en: "Poor ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسطة ",
                    en: "Fair ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيّدة ",
                    en: "Good ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "جيّدة جداً ",
                    en: "Very good ",
                  },
                },
              ],
            },
          },
          {
            _id: 69,
            title: {
              ar: "السؤال السادس",
              en: "6th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في كلّ من البرامج التالية إذا تمّ عرضها عليك في العمل: جلسات النشاط البدني",
                en: "How likely are you to take part in each of the following programmes if they were offered to you at work: Physical activity sessions",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية",
                    en: "Extremely unlikely",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/ don’t know",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 70,
            title: {
              ar: "السؤال السابع",
              en: "7th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في كلّ من البرامج التالية إذا تمّ عرضها عليك في العمل: جلسات تخفيف التوتر مثل المساج أو اليوجا",
                en: "How likely are you to take part in each of the following programmes if they were offered to you at work: Stress-buster sessions such as massage or yoga",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية",
                    en: "Extremely unlikely",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/ don’t know",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 71,
            title: {
              ar: "السؤال الثامن",
              en: "8th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في كلّ من البرامج التالية إذا تمّ عرضها عليك في العمل: المناسبات الاجتماعيّة، على سبيل المثال نزهات الموظفين",
                en: "How likely are you to take part in each of the following programmes if they were offered to you at work: Social events, e.g., staff outings",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية",
                    en: "Extremely unlikely",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/ don’t know",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 72,
            title: {
              ar: "السؤال التاسع",
              en: "9th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في كلّ من البرامج التالية إذا تمّ عرضها عليك في العمل: المحادثات حول التوعية بالسلامة النفسية",
                en: "How likely are you to take part in each of the following programmes if they were offered to you at work: Talks on mental health awareness",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية",
                    en: "Extremely unlikely",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/ don’t know",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 73,
            title: {
              ar: "السؤال العاشر",
              en: "10th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في كلّ من البرامج التالية إذا تمّ عرضها عليك في العمل: محادثات حول الديون والتمويل",
                en: "How likely are you to take part in each of the following programmes if they were offered to you at work: Talks on debt and finance",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية",
                    en: "Extremely unlikely",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/ don’t know",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 74,
            title: {
              ar: "السؤال الحادي عشر",
              en: "11th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في كلّ من البرامج التالية إذا تمّ عرضها عليك في العمل: محادثات حول مشكلات العلاقات",
                en: "How likely are you to take part in each of the following programmes if they were offered to you at work: Talks on relationship problems",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية",
                    en: "Extremely unlikely",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/ don’t know",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 75,
            title: {
              ar: "السؤال الثاني عشر",
              en: "12th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في كلّ من البرامج التالية إذا تمّ عرضها عليك في العمل: محادثات حول إدارة الوقت",
                en: "How likely are you to take part in each of the following programmes if they were offered to you at work: Talks on time management",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية",
                    en: "Extremely unlikely",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/ don’t know",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 76,
            title: {
              ar: "السؤال الثالث عشر",
              en: "13th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في كلّ من البرامج التالية إذا تمّ عرضها عليك في العمل: مجموعة التوقّف عن التدخين",
                en: "How likely are you to take part in each of the following programmes if they were offered to you at work: A stop smoking group",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية",
                    en: "Extremely unlikely",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/ don’t know",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 77,
            title: {
              ar: "السؤال الرابع عشر",
              en: "14th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في كلّ من البرامج التالية إذا تمّ عرضها عليك في العمل: برنامج الأكل الصحيّ",
                en: "How likely are you to take part in each of the following programmes if they were offered to you at work: A healthy eating programme",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية",
                    en: "Extremely unlikely",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/ don’t know",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 78,
            title: {
              ar: "السؤال الخامس عشر",
              en: "15th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في كلّ من البرامج التالية إذا تمّ عرضها عليك في العمل: برنامج إدارة الوزن",
                en: "How likely are you to take part in each of the following programmes if they were offered to you at work: A weight management programme",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية",
                    en: "Extremely unlikely",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/ don’t know",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 79,
            title: {
              ar: "السؤال السادس عشر",
              en: "16th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في كلّ من البرامج التالية إذا تمّ عرضها عليك في العمل: البرامج التعليميّة مثل سلامة الظهر والرعاية الذاتية",
                en: "How likely are you to take part in each of the following programmes if they were offered to you at work: Educational programmes such as back safety, self-help care",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية",
                    en: "Extremely unlikely",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/ don’t know",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
        ],
      },
      {
        title: {
          ar: "السلامة النفسية في العمل",
          en: "Mental wellbeing at work",
        },
        description: {
          ar: "الهدف من هذا القسم هو فهم مدى رضا الموظفين عن فرص رعاية صحتهم النفسيّة، وسيبحث أيضاً في كيفيّة توفير مكان العمل فرصاً لهم للقيام بذلك.",
          en: "The aim of this section is to understand how satisfied employees are with the opportunities for looking after their mental wellbeing. It’ll also look at how the workplace can provide opportunities for them to do this.",
        },
        iconURL: "https://www.svgrepo.com/show/333728/brain.svg",
        _id: "62dffec9251aaf94058278b7",
        steps: [
          {
            _id: 38,
            title: {
              ar: "السؤال الاول",
              en: "1st Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن كمية المعلومات المتعلقة بالصحة العقلية التي تقدمها مؤسستك؟",
                en: "Are you satisfied with the amount of mental wellbeing information that your organisation provides?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 39,
            title: {
              ar: "السؤال الثاني",
              en: "2nd Question",
            },
            type: "Single",
            props: {
              header: {
                ar: `هل أنت راضٍ عن الفرص المتاحة لك للعناية بصحتك وسلامتك العقلية قبل العمل أو أثناءه أو بعده؟
                على سبيل المثال، جلسات النشاط البدني، أو جلسات التخلص من التوتر مثل جلسات اليوغا أو التدليك،
                أو برنامج الأكل الصحي.`,
                en: "Are you satisfied with the opportunities you have for looking after your health and mental wellbeing before, during or after work? For example, physical activity sessions, stressbuster sessions such as yoga or massage sessions, or a healthy eating programme.",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 40,
            title: {
              ar: "السؤال الثالث",
              en: "3rd Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن توافق قدراتك ومهاراتك مع متطلبات وظيفتك؟",
                en: "Are you satisfied that your abilities and skills match the demands of your job?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 41,
            title: {
              ar: "السؤال الرابع",
              en: "4th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن إتمام المهام التي حددتها لإنجازها؟",
                en: "Are you satisfied that the tasks you set out to accomplish are completed?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 42,
            title: {
              ar: "السؤال الخامس",
              en: "5th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن اعتبارك أنت وعملك قيمين وأنك تشعر بالرضا عن نفسك في العمل؟",
                en: "Are you satisfied that you and your work are seen as valuable and that you feel good about yourself at work?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 43,
            title: {
              ar: "السؤال السادس",
              en: "6th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت مقتنع بأن فرص التدريب المتاحة لك مناسبة لك ولوظيفتك؟",
                en: "Are you satisfied that the training opportunities you have are appropriate for you and your job?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 44,
            title: {
              ar: "السؤال السابع",
              en: "7th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن البيئة المادية لمكان عملك؟",
                en: "Are you satisfied with the physical environment of your workplace?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 45,
            title: {
              ar: "السؤال الثامن",
              en: "8th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن حجم تأثيرك على القرارات المتعلقة بوظيفتك؟",
                en: "Are you satisfied with the amount of influence you have over decisions related to your job?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 46,
            title: {
              ar: "السؤال التاسع",
              en: "9th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن مشاركتك في عمليات صنع القرار داخل مؤسستك؟",
                en: "Are you satisfied that you are involved in the decision-making processes within your organisation?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 47,
            title: {
              ar: "السؤال العاشر",
              en: "10th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن ساعات عملك؟",
                en: "Are you satisfied with your working hours?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 48,
            title: {
              ar: "السؤال الحادي عشر",
              en: "11th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل تعتقد أن ساعات العمل المفرطة أمر غير محبذ في مكان عملك؟",
                en: "Do you think that excessive working hours are discouraged at your workplace?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 49,
            title: {
              ar: "السؤال الثاني عشر",
              en: "12th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن الفرص المتاحة لك للعمل المرن؟",
                en: "Are you satisfied with the opportunities you have for flexible working?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 50,
            title: {
              ar: "السؤال الثالث عشر",
              en: "13th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن علاقاتك مع زملائك في العمل؟",
                en: "Are you satisfied with the relationships you have with colleagues at work?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 51,
            title: {
              ar: "السؤال الرابع عشر",
              en: "14th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن معاملتك كفرد من قبل مديرك (مدراءك)؟",
                en: "Are you satisfied that you are treated as an individual by your manager(s)?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 52,
            title: {
              ar: "السؤال الخامس عشر",
              en: "15th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن حصول الجميع على فرصة عادلة للتقدم والتطور؟",
                en: " Are you satisfied that everyone has a fair opportunity to progress and develop?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 53,
            title: {
              ar: "السؤال السادس عشر",
              en: "16th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن عملك أنت وزملائك معًا بطريقة داعمة وتعاونية؟",
                en: "Are you satisfied that you and your colleagues work together in a supportive and collaborative way?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 54,
            title: {
              ar: "السؤال السابع عشر",
              en: "17th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن اعتبار التنمر والتحرش أمراً غير مقبول في العمل، وأنه يتم التعامل مع الشكاوى دائمًا؟",
                en: "Are you satisfied that bullying and harassment are seen as unacceptable at work, and that complaints are always acted on?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 55,
            title: {
              ar: "السؤال الثامن عشر",
              en: "18th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن قنوات الاتصال الموجودة داخل مؤسستك؟",
                en: "Are you satisfied with the channels of communication that exist within your organisation?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 56,
            title: {
              ar: "السؤال التاسع عشر",
              en: "19th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن الطريقة التي يمكنك بها التواصل مع الآخرين في مكان عملك؟",
                en: "Are you satisfied with the way you are able to communicate with other people in your workplace?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 57,
            title: {
              ar: "السؤال العشرين",
              en: "20th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن الطريقة التي يتواصل بها الناس معك عادة؟",
                en: "Are you satisfied with the way people usually communicate with you?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 58,
            title: {
              ar: "السؤال الواحد والعشرين",
              en: "21st Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت مقتنع بأن مديرك المباشر سوف يستمع إلى مخاوفك ويتخذ الإجراء المناسب؟",
                en: " Are you satisfied your line manager will listen to your concerns and take appropriate action?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 59,
            title: {
              ar: "السؤال الثاني والعشرين",
              en: "22nd Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن استخدام جلسات الإشراف بشكل بناء لإدارة عبء العمل الخاص بك وتقديم تعليقات إيجابية؟",
                en: "Are you satisfied that supervision sessions are used constructively to manage your workload and to provide positive feedback?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 60,
            title: {
              ar: "السؤال الثالث والعشرين",
              en: "23rd Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن دورك ومسؤولياتك المحددة بوضوح؟",
                en: "Are you satisfied that you have a clearly defined role and responsibilities?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 61,
            title: {
              ar: "السؤال الرابع والعشرين",
              en: "24th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن تدريبك الوظيفي؟",
                en: "Are you satisfied with your job-related training?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 62,
            title: {
              ar: "السؤال الخامس والعشرين",
              en: "25th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن التدريب الذي حصلت عليه في مجال التنمية الشخصية؟",
                en: "Are you satisfied with your personal development training?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
          {
            _id: 63,
            title: {
              ar: "السؤال السادس والعشرين",
              en: "26th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل أنت راضٍ عن جودة التدريب المقدم لك من خلال العمل؟",
                en: "Are you satisfied with the quality of training you are offered through work?",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "ضعيف للغاية",
                    en: "Very dissatisfied ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "ضعيف",
                    en: "Dissatisfied ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "متوسط",
                    en: "Neutral",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "جيد",
                    en: "Satisfied ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "رائع",
                    en: "Very satisfied ",
                  },
                },
              ],
            },
          },
        ],
      },
      {
        title: {
          ar: "الاهتمامات المستقبلية",
          en: "Future Interests",
        },
        description: {
          ar: "سيساعد فهم اهتمام الموظفين بالحصول على صحة أفصل في تطوير البرنامج وهذا هو الهدف من هذا القسم، حيث تتمّ تغطية الأسئلة المتعلقة بالنشاط البدني والأكل الصحي في كلّ من هذين المجالين.",
          en: "The aim of this section is to understand staff’s’ interest in getting healthier. This will help the development of the programme. Questions on physical activity and healthy eating are covered in each of these two areas.",
        },
        iconURL: "https://www.svgrepo.com/show/331893/lightbulb.svg",
        _id: "62dffec9251aaf94058278b2",
        steps: [
          {
            _id: 2,
            title: {
              ar: "السؤال الاول",
              en: "1st Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في النشاطات البدنيّة في حال تم عرض المبادرات التالية في العمل: محادثات وعروض تقديميّة عن النشاط البدني من قبل أخصائيين صحيين.",
                en: "If the following physical activity initiatives were offered at work, how likely would you be to take part or use them: Talks and presentations on physical activity by health professionals",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 3,
            title: {
              ar: "السؤال الثاني",
              en: "2nd Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في النشاطات البدنيّة في حال تم عرض المبادرات التالية في العمل: رسائل لنشاط بدني أسبوعي عبر البريد الإلكتروني و/أو لوحة الإعلانات. ",
                en: "If the following physical activity initiatives were offered at work, how likely would you be to take part or use them: Access to weekly physical activity messages via e-mail and/or bulletin  boards",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 4,
            title: {
              ar: "السؤال الثالث",
              en: "3rd Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في النشاطات البدنيّة في حال تم عرض المبادرات التالية في العمل: جلسات ونشاطات ضمن مرافق الترفيه المحليّة",
                en: "If the following physical activity initiatives were offered at work, how likely would you be to take part or use them: Active taster sessions at local leisure facilities",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 5,
            title: {
              ar: "السؤال الرابع",
              en: "4th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في النشاطات البدنيّة في حال تم عرض المبادرات التالية في العمل: جلسات ونشاطات في الموقع نفسه تحت إشراف متخصصون في الصحة",
                en: "If the following physical activity initiatives were offered at work, how likely would you be to take part or use them: On-site taster sessions run by health professionals",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 6,
            title: {
              ar: "السؤال الخامس",
              en: "5th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في النشاطات البدنيّة في حال تم عرض المبادرات التالية في العمل: مجموعة نشاطات وقت الغداء، على سبيل المثال المشي وركوب الدراجات والسباحة",
                en: "If the following physical activity initiatives were offered at work, how likely would you be to take part or use them: A lunchtime activity group,e.g. walking, cycling, swimming",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 7,
            title: {
              ar: "السؤال السادس",
              en: "6th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في النشاطات البدنيّة في حال تم عرض المبادرات التالية في العمل: النوادي الرياضية أو النشاطات، على سبيل المثال المشي وكرة القدم وكرة الريشة..",
                en: "If the following physical activity initiatives were offered at work, how likely would you be to take part or use them: Sport or activity clubs, e.g. walking, football or badminton",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 8,
            title: {
              ar: "السؤال السابع",
              en: "7th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في النشاطات البدنيّة في حال تم عرض المبادرات التالية في العمل: النشاطات في الموقع، على سبيل المثال اليوجا والتمرينات الرياضيّة والتاي تشي (فن قتالي دفاعي",
                en: "If the following physical activity initiatives were offered at work, how likely would you be to take part or use them: On-site activity classes, e.g. yoga, aerobics, Tai Chi ",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 9,
            title: {
              ar: "السؤال الثامن",
              en: "8th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في النشاطات البدنيّة في حال تم عرض المبادرات التالية في العمل: النشاطات في المرافق، على سبيل المثال صالة رياضيّة مصممة لهذا الغرض. ",
                en: "If the following physical activity initiatives were offered at work, how likely would you be to take part or use them: On-site facilities, e.g. purpose-built gym",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 10,
            title: {
              ar: "السؤال التاسع",
              en: "9th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في النشاطات البدنيّة في حال تم عرض المبادرات التالية في العمل: اتحادات الشركات والمسابقات",
                en: "If the following physical activity initiatives were offered at work, how likely would you be to take part or use them: Company leagues, ladders and competitions",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 11,
            title: {
              ar: "السؤال العاشر",
              en: "10th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في النشاطات البدنيّة في حال تم عرض المبادرات التالية في العمل: تحديات نشاط الفريق",
                en: "If the following physical activity initiatives were offered at work, how likely would you be to take part or use them: Team activity challenges ",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 12,
            title: {
              ar: "السؤال الحادي عشر",
              en: "11th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في النشاطات البدنيّة في حال تم عرض المبادرات التالية في العمل: عضويات الشركات المدعومة في المرافق المحليّة أو النوادي الصحيّة",
                en: "If the following physical activity initiatives were offered at work, how likely would you be to take part or use them: Subsidised corporate memberships at local facilities or health clubs",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 13,
            title: {
              ar: "السؤال الثاني عشر",
              en: "12th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في النشاطات البدنيّة في حال تم عرض المبادرات التالية في العمل: ساعات عمل مرّنة للسماح بممارسة النشاط البدني قبل وأثناء وبعد العمل.",
                en: "If the following physical activity initiatives were offered at work, how likely would you be to take part or use them: Flexible working hours to allow for physical activity before, during and after work ",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 14,
            title: {
              ar: "السؤال الثالث عشر",
              en: "13th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في النشاطات البدنيّة في حال تم عرض المبادرات التالية في العمل: الاستحمام وأماكن للتغيير ووجود خزائن وأماكن تخزين.",
                en: "If the following physical activity initiatives were offered at work, how likely would you be to take part or use them: Use of showers, changing facilities, lockers and storage facilities",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 15,
            title: {
              ar: "السؤال الرابع عشر",
              en: "14th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في النشاطات البدنيّة في حال تم عرض المبادرات التالية في العمل: تقييمات الصحة واللياقة و/أو الفحص الصحي",
                en: "If the following physical activity initiatives were offered at work, how likely would you be to take part or use them: Health and fitness assessments and/or health screening",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 16,
            title: {
              ar: "السؤال الخامس عشر",
              en: "15th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "ما مدى احتماليّة مشاركتك في النشاطات البدنيّة في حال تم عرض المبادرات التالية في العمل: استخدام الدراجات الهوائيّة الخاصة بالشركة أثناء استراحات الغداء والقيام برحلات قصيرة إلى الاجتماعات.",
                en: "If the following physical activity initiatives were offered at work, how likely would you be to take part or use them: A company bicycle pool foruse during lunch breaks and for making short journeys to meetings",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 17,
            title: {
              ar: "السؤال السادس عشر",
              en: "16th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: وصفات ونصائح لتناول الطعام الصحي",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Recipes and tips for healthyeating",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 18,
            title: {
              ar: "السؤال السابع عشر",
              en: "17th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: دروس الطبخ",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Cookery classes",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 19,
            title: {
              ar: "السؤال الثامن عشر",
              en: "18th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: دورات التغذية ",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Nutrition courses ",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 20,
            title: {
              ar: "السؤال التاسع عشر",
              en: "19th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: محادثات وعروض تقديمية وورش عمل حول الأكل الصحي من قبل المتخصصين في مجال الصحة أو أخصائيي التغذية أو أخصائيي التغذية",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Talks, presentations and workshops on healthy eating by health professionals, dietitians or nutritionists",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 21,
            title: {
              ar: "السؤال العشرين",
              en: "20th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: الوصول إلى مواد تعزيز الصحة مثل المنشورات والملصقات التي تروج للأكل الصحي",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Access to health promotion materials such as leaflets and posters promoting healthy eating",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 22,
            title: {
              ar: "السؤال الواحد والعشرين",
              en: "21st Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: الوصول إلى رسائل الأكل الصحي الأسبوعية عبر البريد الإلكتروني و/أو لوحات النشرات",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Access to weekly healthy eating messages via email and/or bulletin boards",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 23,
            title: {
              ar: "السؤال الثاني والعشرين",
              en: "22nd Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: الحصول على مياه الشرب لجميع الموظفين",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Access to drinking water for all employees",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 24,
            title: {
              ar: "السؤال الثالث والعشرين",
              en: "23rd Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: فاكهة مجانية في مكان العمل",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Free fruit in the workplace",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 25,
            title: {
              ar: "السؤال الرابع والعشرين",
              en: "24th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: اختيار المرطبات الصحية في غرف الاجتماعات - على سبيل المثال، الماء والفواكه الطازجة",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Selection of healthy refreshments in meeting rooms – for example, water and fresh fruits",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 26,
            title: {
              ar: "السؤال الخامس والعشرين",
              en: "25th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: مخطط توصيل صناديق الفواكه والخضروات إلى مكان العمل ",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Fruit and vegetable box delivery scheme to the workplace ",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 27,
            title: {
              ar: "السؤال السادس والعشرين",
              en: "26th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: مرافق الفحص الذاتي، على سبيل المثال، موازين الوزن وأشرطة القياس المتوفرة في منطقة خاصة",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Self-check facilities, e.g. weighing scales and tape measures available in a private area",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 28,
            title: {
              ar: "السؤال السابع والعشرين",
              en: "27th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: برامج إدارة الوزن",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Weight management programmes",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 29,
            title: {
              ar: "السؤال الثامن والعشرين",
              en: "29th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: تقييمات الصحة واللياقة البدنية",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Health and fitness assessments",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 30,
            title: {
              ar: "السؤال التاسع والعشرين",
              en: "29th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: بار الإفطار الصحي",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Healthy breakfast bar",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 31,
            title: {
              ar: "السؤال الثلاثين",
              en: "30th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: خيارات الوجبات الصحية المتوفرة في مطعم أو مقهى الموظف",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Healthy meal choices available in the employee restaurant or café",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 32,
            title: {
              ar: "السؤال الواحد والثلاثين",
              en: "31st Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: شطيرة صحية أو خدمة توصيل الغداء",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Healthy sandwich or lunch delivery service",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 33,
            title: {
              ar: "السؤال الثاني والثلاثين",
              en: "32nd Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: خيارات الوجبات الصحية في آلات البيع - على سبيل المثال، السندويشات والمعكرونة والفواكه الطازجة والزبادي",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Healthy meal options in the vending machines – for example, sandwiches, pasta, fresh fruit and yoghurts",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 34,
            title: {
              ar: "السؤال الثالث والثلاثين",
              en: "33rd Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: خيارات الوجبات الخفيفة الصحية المتوفرة في آلات البيع أو مطاعم الوجبات الخفيفة",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Healthy snack options available in the vending machines or snack bars",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 35,
            title: {
              ar: "السؤال الرابع والثلاثين",
              en: "34th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: المرافق الموجودة في الموقع، على سبيل المثال، مناطق إعداد الطعام وتخزينه لاستخدام الموظفين",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: On-site facilities, e.g. food preparation and storage areas for employees’ use",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 36,
            title: {
              ar: "السؤال الخامس والثلاثين",
              en: "35th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: المشاركة في فعاليات الأكل الصحي المحلية أو الوطنية",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Participation in local or national healthy eating events",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
          {
            _id: 37,
            title: {
              ar: "السؤال السادس والثلاثين",
              en: "37th Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "إذا تم تقديم مبادرات الأكل الصحي التالية في العمل، ما مدى احتمالية مشاركتك فيها أو استخدامها: التحديات الجماعية أو الفردية",
                en: "If the following healthy eating initiatives were offered at work, how likely would you be to take part or use them: Team or individual challenges",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "غير محتمل للغاية ",
                    en: "Extremely unlikely ",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "غير محتمل إلى حدّ ما ",
                    en: "Fairly unlikely ",
                  },
                },
                {
                  value: 3,
                  label: {
                    ar: "محايد/لا أعرف ",
                    en: "Undecided/don’t know ",
                  },
                },
                {
                  value: 4,
                  label: {
                    ar: "محتمل إلى حدّ ما ",
                    en: "Fairly likely ",
                  },
                },
                {
                  value: 5,
                  label: {
                    ar: "محتمل للغاية ",
                    en: "Extremely likely ",
                  },
                },
              ],
            },
          },
        ],
      },
      {
        title: {
          ar: "اقتراحات",
          en: "Comments",
        },
        description: {
          ar: "في هذا القسم الأخير، إذا كانت لديك أي توصيات أو مشكلات أو اقتراحات فلا تتردد في كتابتها أدناه. لا تقلق، كل شيء مجهول.",
          en: "In this Final Section, if you have any recommendations, issues or suggestions please feel free to write them below. Don’t worry, it’s all anonymous.",
        },
        iconURL: "https://www.svgrepo.com/show/520659/comments-lines.svg",
        _id: "62dffec9251aaf94058278bd",
        steps: [
          {
            _id: 82,
            title: {
              ar: "السؤال الاول",
              en: "1st Question",
            },
            type: "Single",
            props: {
              header: {
                ar: "هل ترغب في المشاركة في برنامجنا الخاص بالصحة والسلامة؟",
                en: "Would you like to be involved in our health and wellbeing programme? ",
              },
              description: {
                ar: "اختر إجابة واحدة فقط",
                en: "Tick only one",
              },
              options: [
                {
                  value: 1,
                  label: {
                    ar: "لا",
                    en: "No",
                  },
                },
                {
                  value: 2,
                  label: {
                    ar: "نعم",
                    en: "Yes",
                  },
                },
              ],
            },
          },
          {
            _id: 83,
            title: {
              ar: "السؤال الثاني",
              en: "2nd Question",
            },
            type: "Text",
            props: {
              header: {
                ar: "هل لديك أيّ ملاحظات أو اقتراحات أخرى؟",
                en: "Do you have any other comments or suggestions? ",
              },
              description: {
                ar: "",
                en: "",
              },
            },
          },
        ],
      },
    ],
  },
};

export default SURVEY_QUESTIONS;
