import React, { useState } from "react";

import Button from "../atoms/Button";
import Line from "../atoms/Line";
import TopicSurvey from "../molecules/TopicSurvey";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import useSurvey from "src/hooks/useSurvey";
import { useHistory } from "react-router-dom";
import useSurveyLabel from "src/hooks/useSurveyLabel";
import { P } from "../atoms/Text";
import { getCompanyNameFromSurveyURL } from "src/helper/navigation";
import { useSurveyTrackingDetails } from "src/hooks/useSurveyTrackingDetails";
function DrawerSurvey() {
  const history = useHistory();
  const companyName = getCompanyNameFromSurveyURL();

  const { PERCENT_OF_COMPLETE } = useSurveyTrackingDetails();

  const label = useSurveyLabel();
  const { data, companyInfo, onSubmitSurvey } = useSurvey();
  const [loading, setLoading] = useState(false);

  return (
    <div className="h-screen bg-white w-[400px] hidden lg:flex flex-col flex-shrink-0  ">
      <div
        className="flex w-full justify-center items-center h-24 border-b cursor-pointer  flex-shrink-0  "
        style={{ height: "10vh" }}
        onClick={() => history.push(`/survey/${companyName}`)}
      >
        <img src={companyInfo.logo} alt="logo" className="h-20 " />
        <div className="w-3" />
        {/* <P className={"text-xl font-bold"}>{companyInfo.name}</P> */}
      </div>

      {/* <Line className={"my-4"} /> */}
      <div className="overflow-y-auto p-3 mt-0">
        {data.map((item) => (
          <TopicSurvey key={item._id} data={item} />
        ))}
      </div>
      <div className="flex-1" />
      <Line className={"my-3"} />
      <div className="h-80 w-full  flex justify-center items-center flex-col p-3">
        <div style={{ width: "100px", height: "100px", marginBottom: "30px" }}>
          <CircularProgressbar
            value={PERCENT_OF_COMPLETE}
            text={`${PERCENT_OF_COMPLETE.toFixed(1)}%`}
            styles={buildStyles({
              trailColor: "#F0F8FF",
              pathColor: "#00BED6",
              textColor: "black",
            })}
          />
        </div>
        <P
          className={` text-center px-4 text-sm whitespace-pre-line mb-3 ${
            PERCENT_OF_COMPLETE !== 100 ? "opacity-100" : "opacity-0"
          }`}
        >
          {label["you_cant_submit"]}
        </P>

        {/* <Button
          onClick={async () => {
            setLoading(true);
            onSubmitSurvey().finally(() => setLoading(false));
          }}
          loading={loading}
          disabled={PERCENT_OF_COMPLETE !== 100}
          className={"rounded-2xl "}
        >
          {label["submit_survey"]}
        </Button> */}
      </div>
    </div>
  );
}

export default DrawerSurvey;
