import { createContext, useContext, useMemo } from "react";
import { useLabayhContext } from "src/context/LabayhAdmin";

const LanguageContext = createContext({
  isEn: false,
  lang: "en",
  isAr: true,
  dir: "ltr",
  textAlign: "left",
  textAlignReverse: "right",
  setLang: (v) => {},
});

export function LanguageProvider({ children }) {
  const {
    state: { lang },
    changeLnag,
  } = useLabayhContext();

  const isEn = useMemo(() => lang === "en", [lang]);
  const isAr = useMemo(() => lang === "ar", [lang]);
  return (
    <LanguageContext.Provider
      value={{
        lang,
        dir: isEn ? "ltr" : "rtl",
        isEn,
        isAr,
        textAlign: isEn ? "left" : "right",
        textAlignReverse: !isEn ? "left" : "right",
        setLang: (newLang) => changeLnag(newLang),
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export const useLang = (objectLabels) => {
  const { dir, isAr, isEn, lang, setLang, textAlign, textAlignReverse } =
    useContext(LanguageContext);

  const labelsTranslated = useMemo(() => {
    if (typeof objectLabels !== "object") return {};
    let result = Object.keys(objectLabels)
      .map((key) => {
        return { key, value: objectLabels[key][lang] };
      }, [])
      .reduce((acc, current) => {
        acc[current.key] = current.value;
        return acc;
      }, {});

    return result;
  }, [lang, objectLabels]);

  return {
    dir,
    isAr,
    isEn,
    lang,
    setLang,
    textAlign,
    textAlignReverse,
    labelsTranslated,
  };
};
