import React, { useState } from "react";

import { useEffect } from "react";

import { isGoDirectly, TYPE_QUESTION } from "src/constants/TOPIC_SURVEY";
import { useTranslator } from "src/context/storeTranslator";
import { useTrackerSurvey } from "src/context/trackSurvey";

import useSurvey, { convertAnswerToBoolean } from "src/hooks/useSurvey";

import { P } from "../atoms/Text";
import ButtonsPreNext from "../molecules/Buttons_PRE_NEXT";
import SwitchQuestions from "../molecules/SwitchQuestions";

import { SurveyComponent } from "../survey_components";
import Button from "../atoms/Button";
import { useSurveyTrackingDetails } from "src/hooks/useSurveyTrackingDetails";
import useSurveyLabel from "src/hooks/useSurveyLabel";
import { Row } from "react-bootstrap";

function SurveyContent({
  topic,

  onNextSection,
  isLastSection,
  loadingSubmit,
  IS_ALL_COMPLETED,
  isFirstChild,
}) {
  let { step, setStep } = useTrackerSurvey();
  const CurrentStep = topic?.steps[step];
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const label = useSurveyLabel();

  const { answer, setAnswers: addAnswer, onSubmitSurvey } = useSurvey();
  const { PERCENT_OF_COMPLETE } = useSurveyTrackingDetails();

  const [translate] = useTranslator();

  const [startAnimation, setStartAnimation] = useState(false);
  useEffect(() => {
    setStartAnimation(true);
  }, [step]);
  const isLastQ = topic?.steps?.length === step + 1;

  return (
    <div className=" flex-1 flex flex-col relative  ">
      <div className={`lg:pt-3 flex relative flex-col flex-1 w-full }   `}>
        <div
          className={`flex-1   ${startAnimation ? "animation-Up" : ""}`}
          onAnimationEnd={() => setStartAnimation(false)}
        >
          <div className="lg:h-5" />
          <div className="w-full flex lg:justify-center">
            <P className={"text-gray-400 px-5 pt-2   "}>
              {translate === "en" ? "Question" : "السؤال"} {step + 1}/
              {topic?.steps.length}
            </P>
          </div>
          <div className="h-2 lg:h-5 " />
          <div>
            {!!CurrentStep && (
              <MainQuestion
                data={CurrentStep}
                onChange={(v) => {
                  addAnswer(CurrentStep._id, v);
                  if (isGoDirectly(CurrentStep.type)) {
                    // if (
                    //   device.IS_PC &&
                    //   CurrentStep.type === TYPE_QUESTION.RANGE
                    // )
                    //   return;
                    setTimeout(() => {
                      if (isLastQ) onNextSection(true);
                      else {
                        setStep(step + 1);
                        setError("");
                      }
                    }, 400);
                  }
                }}
                answer={answer}
                error={error}
              />
            )}
          </div>
        </div>
        {/* <ButtonsPreNext
          current={CurrentStep}
          onNextSection={onNextSection}
          isLastSection={isLastSection}
          showButton={
            (convertAnswerToBoolean(answer[CurrentStep?._id]) &&
              !isGoDirectly(CurrentStep?.type)) ||
            (isLastSection && isLastQ)
          }
          isLast={isLastQ}
          loadingSubmit={loadingSubmit}
          IS_ALL_COMPLETED={IS_ALL_COMPLETED}
          answer={answer}
        /> */}

        <Row className=" mt-4 w-full flex-shrink-0   lg:bg-opacity-0 relative z-30   p-4 px-5 lg:px-4">
          <Row
            className={`flex lg:pl-5 flex-1 btn-next-ui !border-0 bg-theme-logo  flex-shrink-0  justify-center lg:px-5 `}
            style={{ border: 0 }}
          >
            <Button
              onClick={async () => {
                setLoading(true);
                onSubmitSurvey().catch(() => setLoading(false));
              }}
              loading={loading}
              disabled={loading || PERCENT_OF_COMPLETE !== 100}
              className={"rounded-2xl"}
            >
              {label["submit_survey"]}
            </Button>
          </Row>
        </Row>
      </div>
      <SwitchQuestions
        current={CurrentStep}
        answer={answer}
        setError={setError}
      />
    </div>
  );
}
function MainQuestion({ data, onChange, answer, error }) {
  if (!data) return;

  const mainProps = {
    value: answer[data._id],
    onChange: onChange,
    onEnter: () => {
      const btn = document.querySelector("#btn-next");
      btn.click();
    },
  };
  return (
    <div className="flex flex-col items-center ">
      <div className="w-full lg:w-[60%] ">
        {data.type === TYPE_QUESTION.RANGE && (
          <SurveyComponent.SliderRange {...data.props} {...mainProps} />
        )}
        {data.type === TYPE_QUESTION.MULTIPLE_CHOOSE && (
          <SurveyComponent.MultipleChoose {...data.props} {...mainProps} />
        )}
        {data.type === TYPE_QUESTION.CHOOSE_FORM && (
          <SurveyComponent.ChooseForm {...data.props} {...mainProps} />
        )}
        {data.type === TYPE_QUESTION.BOOLEAN && (
          <SurveyComponent.BooleanForm {...data.props} {...mainProps} />
        )}
        {data.type === TYPE_QUESTION.TEXT_AREA && (
          <SurveyComponent.TextArea {...data.props} {...mainProps} />
        )}
        {data.type === TYPE_QUESTION.NUMERIC && (
          <SurveyComponent.Numeric {...data.props} {...mainProps} />
        )}

        <div className="w-full flex flex-col lg:items-center  px-5 text-red-500">
          {error ? <>{error}</> : null}
        </div>
      </div>
    </div>
  );
}
export default SurveyContent;
