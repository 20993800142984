import React, { useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FaCheckCircle } from "react-icons/fa";
import { MdLanguage } from "react-icons/md";

import { LANG } from "src/constants/LANG";
import { useTranslator } from "src/context/storeTranslator";
import useSurveyLabel from "src/hooks/useSurveyLabel";
import { P } from "../atoms/Text";

function SwitchLang({ sm, reverse }) {
  const [value, setTranslator] = useTranslator();
  const label = useSurveyLabel();
  const [open, setOpen] = useState(false);

  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (open) setOpen(false);
    },
  });
  return (
    <div ref={ref} className="relative flex justify-end">
      {open && (
        <div
          className={`absolute bottom-[180%] z-50  lg:bottom-[120%] ${""}  ${
            sm ? (value === "en" ? "left-0 w-32s" : "right-0 w-32s") : ""
          } transform ${
            sm ? (value === "en" ? "-translate-x-40 " : "translate-x-40") : ""
          } w-64 rounded-xl p-3 bg-white  border bor`}
        >
          <P className={`text-gray-400  ${value === "ar" ? "text-right" : ""}`}>
            {label["select_lang"]}
          </P>
          <div className="h-2" />
          {LANG.map((item) => {
            const isSelected = value === item.value;
            return (
              <div
                className={` rounded border-transparent ${
                  isSelected && "border text-theme-logo"
                } mt-1 w-full p-2 flex justify-between items-center cursor-pointer hover:bg-gray-100 `}
                onClick={() => {
                  setTranslator(item.value);
                  setOpen(false);
                }}
              >
                <P
                  className={`font-bold ${
                    isSelected ? "text-theme-primary" : "text-black"
                  }`}
                >
                  {item.label}
                </P>
                {isSelected && <FaCheckCircle className="text-theme-logo" />}
              </div>
            );
          })}
        </div>
      )}
      <div
        onClick={() => setOpen(true)}
        className={`p-2 rounded-xl cursor-pointer text-gray-400 hover:text-gray-600 border ${
          sm ? "w-13" : "w-32"
        } flex items-center`}
      >
        <MdLanguage
          className={
            (sm ? "" : value === "ar" ? "ml-2" : "mr-2") +
            "" +
            (sm ? "text-3xl" : "")
          }
        />
        {!sm && <P>{label["lang"]}</P>}
      </div>
    </div>
  );
}

export default SwitchLang;
