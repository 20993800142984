import axios from 'axios';

const axiosRequestInstance = axios.create({
    baseURL: process.env.REACT_APP_MOBILE_API
});

axiosRequestInstance.interceptors.request.use(
    async (config) => {
        let token = await localStorage.getItem("ctoken");
        if(token){
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

export default axiosRequestInstance;
