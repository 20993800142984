import React from "react";
import { FontFamily } from "src/constants/fontFamily";
import { P } from "./Text";

function TextField({
  label,
  value,
  onChange,
  className,
  type = "text",
  ltr,
  classNameInput,
  noRounded,
  disabled,
  placeholder,
  info,
  icon,
}) {
  return (
    <div className={`mt-2   flex flex-col ${className}`}>
      <P className={` mb-2 `} style={{ fontFamily: FontFamily.FontArabic }}>
        {label}
      </P>
      {info ? (
        <p className="py-1 font-bold">{value}</p>
      ) : (
        <div
          className={`flex-1 flex items-center border-[1px] dark:border-dark-backgroundColor  gap-3   ${
            noRounded ? "" : "rounded-xl"
          } ${
            disabled
              ? "bg-theme-backgroundColorThird dark:bg-dark-backgroundColorThird"
              : "bg-theme-backgroundColor dark:bg-dark-backgroundColor"
          }`}
        >
          {icon && <div className="px-3  text-gray-400">{icon}</div>}
          <input
            className={`flex-1 border-transparent bg-transparent focus:outline-theme-primary px-3 ${
              noRounded ? "" : "rounded-xl"
            } py-2  focus:outline-none ${
              disabled
                ? "text-gray-400"
                : " focus:border-sky-500 focus:ring-sky-500 focus:ring-1 "
            } ${classNameInput}`}
            type={type}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
}

export default TextField;
