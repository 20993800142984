const QUESTONS_SUBSECTION = {
    firstSection: () => 1,
    secondSection: () => 2,
    thirdSection: (val) => {
      return val < 40
        ? 3
        : val < 45
        ? 4
        : val < 50
        ? 5
        : val < 55
        ? 6
        : val < 60
        ? 7
        : 8;
    },
    fourthSection: () => 9,
    fifthSection: () => 10,
  };

  export function getQuestionSection(key) {
    return key < 2
      ? QUESTONS_SUBSECTION.firstSection(key)
      : key < 38
      ? QUESTONS_SUBSECTION.secondSection(key)
      : key < 64
      ? QUESTONS_SUBSECTION.thirdSection(key)
      : key < 80
      ? QUESTONS_SUBSECTION.fourthSection(key)
      : QUESTONS_SUBSECTION.fifthSection(key);
  }