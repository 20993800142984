import React from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useTranslator } from "src/context/storeTranslator";
import useSurvey from "src/hooks/useSurvey";
import useSurveyLabel from "src/hooks/useSurveyLabel";
import { useSurveyTrackingDetails } from "src/hooks/useSurveyTrackingDetails";
import ProgressBar from "../atoms/ProgressBar";
import Row from "../atoms/Row";
import { P } from "../atoms/Text";
import SwitchLang from "../molecules/SwitchLang";
import TopicSurvey from "../molecules/TopicSurvey";
import { useTrackerSurvey } from "../../context/trackSurvey";

function DrawerSurveyMobile({ open, onClose }) {
  const { data } = useSurvey();
  const { isNotCompleted } = useTrackerSurvey();
  const [lang] = useTranslator();
  const { PERCENT_OF_COMPLETE, TOTAL_ANSWERS, COUNT_ANSWERS } =
    useSurveyTrackingDetails();
  const label = useSurveyLabel();
  const moveTo = lang === "en" ? "100vw" : "-100vw";
  const reminder = label["reminder"];
  const NotComplete = label["not_complete"];

  return (
    <div
      className="w-screen transition-all duration-200 h-full fixed top-0 right-0 z-40 bg-black bg-opacity-25 flex"
      style={{ right: open ? "0px" : moveTo }}
    >
      <div className="w-[80vw] h-full bg-white flex flex-col ">
        <div className="px-4 h-20 flex items-center  ">
          <div
            onClick={onClose}
            className=" w-14 h-14 rounded  cursor-pointer hover:bg-gray-50 flex justify-center items-center"
          >
            {lang === "ar" ? (
              <BsChevronRight className={`text-2xl `} />
            ) : (
              <BsChevronLeft className={`text-2xl `} />
            )}
          </div>
          <div className="w-3" />
          <div className="px-2 ">
            <P className="text-xl font-bold ">
              {label["staff_health"]}
              <br className="lg:hidden" /> {label["wellbeing_survey"]}
            </P>
          </div>
        </div>

        <div className="overflow-y-auto px-3  py-4 border-y  mt-3s flex-1 ">
          {data.map((item) => (
            <TopicSurvey key={item._id} data={item} sm />
          ))}
        </div>
        {isNotCompleted && (
          <div className="px-2">
            <div className="p-2  border-red-4s00   my-2 flex items-center   ">
              <span className=" relative flex justify-center items-center h-3 w-3   rounded-full bg-red-500 opacity-75 ">
                <div className="w-3 h-3 bg-red-500 rounded-full animate-ping" />
              </span>
              <div className="w-3" />
              <P className={"text-red-500"}>{NotComplete}</P>
            </div>
          </div>
        )}
        <Row className={"p-3 w-full flex flex-row-reverse "}>
          <SwitchLang sm reverse />
          <div className="w-4" />
          <div className="flex-1 ">
            <ProgressBar
              color={"#486DF6"}
              value={PERCENT_OF_COMPLETE}
              rounded
              label={reminder + " : " + TOTAL_ANSWERS + " / " + COUNT_ANSWERS}
            />
          </div>
        </Row>
      </div>
      <div
        className="flex-1 bg-black opacity-0 hover:opacity-20 cursor-pointer"
        onClick={onClose}
      />
    </div>
  );
}

export default DrawerSurveyMobile;
