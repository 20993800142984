import React from "react";
import useSurveyLabel from "src/hooks/useSurveyLabel";
import HeaderAndDesc from "./HeaderAndDesc";

function ChooseForm({ header, description, onChange, value }) {
  const label = useSurveyLabel();
  return (
    <div>
      <HeaderAndDesc header={header} description={description} />
      <div className="h-3" />
      <div className="flex px-5 lg:justify-center mt-4  ">
        <div
          onClick={() => onChange(true)}
          className={`transition-all duration-200  py-3 text-center border ${
            value === true ? "bg-theme-logo text-white" : "bg-white"
          } border-2 border-logo rounded-xl flex justify-center items-center flex-1 lg:flex-0 lg:w-32 flex-shrink-0  cursor-pointer`}
        >
          {label["yes"]}
        </div>
        <div className="w-5" />
        <div
          onClick={() => onChange(false)}
          className={`transition-all duration-200  py-3 text-center border ${
            value === false ? "bg-theme-logo text-white" : "bg-white"
          }  ml-2 rounded-xl  border-4 border-logo flex justify-center items-center flex-1 lg:flex-0 lg:w-32 flex-shrink-0 cursor-pointer`}
        >
          {label["no"]}
        </div>
      </div>
    </div>
  );
}

export default ChooseForm;
