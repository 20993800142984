import axios from "axios";

const labahMainAPI = axios.create({
  baseURL:
    // 'https://portal.labayh.net/'
    process.env.REACT_APP_LABAYH_20_API,
});
labahMainAPI.interceptors.request.use(
  async (config) => {
    let token = await localStorage.getItem("ctoken");
    // token = process.env.REACT_APP_API_TOKEN;
    const lang = await localStorage.getItem("lang");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      if (lang) {
        config.headers.lang = `${lang}`;
      }
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default labahMainAPI;
