import React, { Fragment, useState } from "react";
import SurveySection from "../molecules/SurveySection";
import SURVEY_BG from "../../assets/SurveyIntro.png";
import useSurvey, { convertAnswerToBoolean } from "src/hooks/useSurvey";
import { useTranslator } from "src/context/storeTranslator";
import { H1 } from "../atoms/Text";
import PowerBy from "../atoms/PowerBy";
import { useEffect } from "react";
import { LANG } from "src/constants/LANG";
import HeaderSurveyIntro from "../molecules/HeaderSurveyIntro";
import useCompanyDetails from "src/hooks/useCompanyDetails";
import Survey404 from "./Survey404";
import labayhAPI from "../../api/labayhAPI";
import LoadingSurvey from "../subPages/LoadingSurvey";

function SurveyIntro() {
  const { data, loading, answer } = useSurvey();

  useEffect(() => {
    if (answer && !loading) {
      if (Object.keys(answer).length > 0) {
        setTimeout(() => {
          const whichOneComplete = data
            .map((d, i) => {
              const isComplete = d.steps.every(
                (current) => !convertAnswerToBoolean(answer[current._id])
              );

              return isComplete ? i : -1;
            })
            .filter((d) => d >= 0);

          // const

          if (whichOneComplete.length > 0) {
            const a = document.createElement("a");
            const indexS = whichOneComplete[0];
            a.href = `#section${indexS}`;
            a.click();
          }
        }, 200);
      }
    }
  }, [answer, loading]);

  return (
    <div className=" w-full relative z-10 flex flex-col    items-center   pt-4 ">
      <div className="w-[90%] lg:w-[60%] flex flex-col       ">
        <HeaderSurveyIntro />
        <div
          className="w-full grid lg:grid-cols-2 gap-5 py-4  "
          // style={{ height: "50vh" }}
        >
          {loading &&
            new Array(4)
              .fill(1)
              .map((_, i) => (
                <div
                  key={i}
                  className="h-32 bg-theme-primary animate-pulse rounded-xl "
                />
              ))}
          {data.map((item, i) => (
            <SurveySection key={item._id} data={item} index={i} />
          ))}
        </div>
        {/* <SwitchLang /> */}
      </div>
    </div>
  );
}

function SurveyIntroPage({ name }) {
  const [isSelectLang, setIsSelectLang] = useState(false);
  const [lng, setLang] = useTranslator();
  const [supportsSurvey, setSupportsSurvey] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await labayhAPI.get(`/api/surveys/${name}`);
        setIsLoading(false);
        setSupportsSurvey(response.data.company.planId.supportsSurvey);
      } catch (err) {
        setIsLoading(false);
        setSupportsSurvey(false)
      }
    };

    getData();
  }, [name]);

  if (isLoading) return <LoadingSurvey />;

  if (supportsSurvey !== undefined && !supportsSurvey) return <Survey404 />;
  return (
    <div
      dir={lng === "ar" ? "rtl" : "ltr"}
      className="w-full h-screen  flex  flex-col items-center    bg-white relative bg-intro-survey"
    >
      <img
        src={SURVEY_BG}
        alt="surveyIntro"
        className="h-full w-full fixed  object-cover top-0 "
      />
      <div
        className={`flex-1 z-10 w-full  flex flex-col items-center ${
          !isSelectLang ? "justify-center" : ""
        }`}
      >
        {isSelectLang && <SurveyIntro />}
        {!isSelectLang && (
          <Fragment>
            <H1>Choose a language</H1>
            <div className="h-3" />
            <H1>اختر اللغة</H1>
            <div className="h-6" />
            {LANG.map((lang) => (
              <div
                onClick={() => {
                  setLang(lang.value);
                  setIsSelectLang(true);
                }}
                className="w-[70%] lg:w-64 p-3 border-[1px] border-theme-primary rounded mb-2 text-center bg-theme-primary bg-opacity-30   cursor-pointer hover:bg-opacity-100 hover:text-white "
              >
                {lang.label}
              </div>
            ))}
          </Fragment>
        )}
      </div>
      <div className="w-[60%]  relative z-20">
        <PowerBy forceLight center></PowerBy>
      </div>
    </div>
  );
}

export default SurveyIntroPage;
