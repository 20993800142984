import React from "react";
import useSurvey from "src/hooks/useSurvey";
import useSurveyLabel from "src/hooks/useSurveyLabel";
import { P } from "../atoms/Text";
const headerColor = `rgba(227, 243, 255, 0.62)`;
function HeaderSurveyIntro() {
  const label = useSurveyLabel();
  const { companyInfo } = useSurvey();
  return (
    <div
      style={{
        backgroundColor: headerColor,
        backdropFilter: "blur(9px)",
      }}
      className="w-full px-4 py-3  h-28 rounded-custom  flex justify-between items-center  "
    >
      <div className={"flex items-center flex-row-reverse"}>
        <img src={companyInfo.logo} alt="" className="h-20  " />
      </div>
      <P className="text-lg font-bold text-center lg:absolute lg:w-full  ">
        {label["staff_health"]}
      </P>
    </div>
  );
}

export default HeaderSurveyIntro;
