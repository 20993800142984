import React, { Fragment } from "react";
import { Row } from "react-bootstrap";

import { useTrackerSurvey } from "src/context/trackSurvey";

import useSurveyLabel from "src/hooks/useSurveyLabel";
import Button from "../atoms/Button";
import { P } from "../atoms/Text";

function Buttons_PRE_NEXT({
  isLast,
  onNextSection,
  current,
  isLastSection,
  loadingSubmit,
  IS_ALL_COMPLETED,
  showButton,
  answer
}) {
  let { step, setStep, setOpen } = useTrackerSurvey();
  const label = useSurveyLabel();

  const disabled = IS_ALL_COMPLETED();
  if(!showButton)
  return <div/>
  return (
    <Row className=" mt-4 w-full flex-shrink-0   lg:bg-opacity-0 relative z-30   p-4 px-5 lg:px-4">
      {/* <div className={`hidden flex-shrink-0 `}>
        <SwitchLang sm />
      </div> */}
      {/* <div className="w-7 lg:w-0" /> */}
      <Row
        className={`flex lg:pl-5 flex-1 btn-next-ui bg-theme-logo  flex-shrink-0  justify-center lg:px-5 `}
      >
        {showButton && (
          <Button
            id={"btn-next"}
            className={`btn-normal `}
            classNameText={"text-xl lg:text-sm"}
            disabled={!!!answer[83]}
            loading={loadingSubmit}
            // iconRight={
            //   translate === "en" ? (
            //     loadingSubmit ? (
            //       <FaSpinner className="animate-spin" />
            //     ) : (
            //       <BsArrowRight />
            //     )
            //   ) : null
            // }
            // icon={
            //   translate === "ar" ? (
            //     loadingSubmit ? (
            //       <FaSpinner className="animate-spin" />
            //     ) : (
            //       <BsArrowLeft />
            //     )
            //   ) : null
            // }

            onClick={() =>
              disabled
                ? setOpen(false, false)
                : isLast
                ? onNextSection(current.title)
                : setStep(++step)
            }
          >
            {isLast ? (
              isLastSection ? (
                label["submit_button"]
              ) : (
                label["next_section"]
              )
            ) : (
              <Fragment>
                <P className="hidden lg:block">{label["next_question"]}</P>
                <P className="block lg:hidden">{label["next"]}</P>
              </Fragment>
            )}
          </Button>
        )}
      </Row>
    </Row>
  );
}

export default Buttons_PRE_NEXT;
