import { createContext } from "react";

const { useContext } = require("react");

export const TrackSurveyStore = createContext({
  step: 0,
  target: 0,
  setTarget: () => {},
  setStep: () => {},
  isFirstQ: "",
  currentStep: "",
  open: false,
  setOpen: () => {},
  isNotCompleted: false,
});

export const useTrackerSurvey = () => useContext(TrackSurveyStore);
