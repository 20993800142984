import SURVEY from "../translations/survey.json";
import { useTranslator } from "src/context/storeTranslator";
import { useCallback } from "react";
function useSurveyLabel() {
  const [lng] = useTranslator();
  const label = useCallback(() => SURVEY[lng] || {}, [lng])();

  return label;
}

export default useSurveyLabel;
