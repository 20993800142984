import walk from "../assets/icons/survey/walk.svg";
import food from "../assets/icons/survey/food.svg";
import mental from "../assets/icons/survey/mental.svg";
import personal from "../assets/icons/survey/personal.svg";
import { faker } from "@faker-js/faker";
//NOTE: TYPE CAN BE ="RANGE"|"MULTIPLE_CHOOSE"|"CHOOSE_FORM"|"SCALE"|"BOOLEAN"

export const TYPE_QUESTION = {
  RANGE: "Scale",
  MULTIPLE_CHOOSE: "MCQ",
  CHOOSE_FORM: "Single",
  BOOLEAN: "Boolean",
  TEXT_AREA: "Text",
  NUMERIC: "Numeric",
};

export const TOPIC_INFO = [
  {
    duration: {
      ar: "أقل من دقيقة",
      en: "less than a minute",
    },
  },
  {
    duration: {
      en: "3 minutes",
      ar: "3 دقائق",
    },
  },
  {
    duration: {
      en: "2 minutes",
      ar: "دقيقتان",
    },
  },
  {
    duration: {
      en: "4 minutes",
      ar: "4 دقائق",
    },
  },
  {
    duration: {
      en: "3 minutes",
      ar: "3 دقائق",
    },
  },
  {
    duration: {
      ar: "أقل من دقيقة",
      en: "less than a minute",
    },
  },
];
export const TYPE_QUESTION_DATA = Object.keys(TYPE_QUESTION).map((item) => ({
  value: TYPE_QUESTION[item],
  label: item,
}));
const GO_DIRECTLY = [
  TYPE_QUESTION.BOOLEAN,
  TYPE_QUESTION.CHOOSE_FORM,
  TYPE_QUESTION.RANGE,
];
export function isGoDirectly(v) {
  // let CONDITION_WORK = GO_DIRECTLY;
  // if (device.IS_PC)
  //   CONDITION_WORK = CONDITION_WORK.filter(
  //     (item) => item !== TYPE_QUESTION.RANGE
  //   );
  if (GO_DIRECTLY.includes(v)) return true;
  return false;
}
const PhysicalActivity = {
  title: "Physical activity",
  desc: "The aim of these question is to obtain basic clues about physical activity and your health lifestyle",
  target: 0,
  icon: walk,
  steps: [
    {
      id: faker.random.alphaNumeric(8),
      label: "Walk",

      type: TYPE_QUESTION.RANGE,
      props: {
        header: "Physically Work",
        desc: `How many days a week do you spend walking continuously for at least 10
minutes at a time? Think about all the walking you have done including
any leisure walking, walking to and from home, and any purposeful
walking.`,
        min: 0,
        max: 7,
      },
    },
    {
      id: faker.random.alphaNumeric(8),
      label: "Sport or Activites",
      type: TYPE_QUESTION.RANGE,
      props: {
        header: "Sports or Activites",
        desc: `How many days during the week do you do any sport or activity? Exercise Sports, yoga, sports or exercise?`,
        min: 0,
        max: 7,
      },
    },
    {
      id: faker.random.alphaNumeric(8),
      label: "More Active",
      type: TYPE_QUESTION.MULTIPLE_CHOOSE,
      props: {
        header: "More Active",
        desc: `How many days during the week do you do any sport or activity? Exercise Sports, yoga, sports or exercise?`,

        options: [
          {
            value: "WORK_COMMITMENTS",
            label: "Work commitments",
            icon: "",
          },
          {
            value: "DISTANCE_TO_FACILITIES",
            label: "Distance to facilities",
            icon: "",
          },
          { value: "INJURY", label: "Injury", icon: "" },
        ],
      },
    },
    {
      id: faker.random.alphaNumeric(8),
      label: "Describe Yourself",
      type: TYPE_QUESTION.CHOOSE_FORM,
      props: {
        header: "Describe Yourself",
        desc: `How many days during the week do you do any sport or activity? Exercise Sports, yoga, sports or exercise?`,
        options: [
          {
            value: "A",
            label:
              "I am not interested in pursuing a healthy lifestyle or being physically active",
          },
          {
            value: "B",
            label: "I have recently been thinking about being more active",
          },
          {
            value: "C",
            label:
              "I am looking to become more active within the next six months.",
          },
          {
            value: "D",
            label:
              "I have recently changed my behaviour and I am active on a more regularly.",
          },
          {
            value: "E",
            label: "I have been regularly active for at least six months.",
          },
        ],
      },
    },
    {
      id: faker.random.alphaNumeric(8),
      label: "Physically Work",
      type: TYPE_QUESTION.CHOOSE_FORM,
      props: {
        header: "Physically Work",
        desc: "The aim of these questions is to obtain basic clues about physical activity and your healthy lifestyle",
        options: [
          { label: "Very Required", value: "A" },
          { label: "Fairly Required", value: "B" },
          { label: "Not too much", value: "C" },
        ],
      },
    },
    {
      id: faker.random.alphaNumeric(8),
      label: "Work Time",
      type: TYPE_QUESTION.CHOOSE_FORM,
      props: {
        header: "Work Time",
        desc: "The aim of these questions is to obtain basic clues about physical activity and your healthy lifestyle",
        options: [
          { label: "Very Required", value: "A" },
          { label: "Fairly Required", value: "B" },
          { label: "Not too much", value: "C" },
        ],
      },
    },
    {
      id: faker.random.alphaNumeric(8),
      label: "Activity Initiatives",
      type: TYPE_QUESTION.TEXT_AREA,
      props: {
        header: "Give us your note",

        desc: "The aim of these questions is to obtain basic clues about physical activity and your healthy lifestyle",
        placeholder: "added here ",
      },
    },
    {
      id: faker.random.alphaNumeric(8),
      label: "Age",
      type: TYPE_QUESTION.NUMERIC,
      props: {
        header: "Enter Your Age",
        desc: "It help us to define",
        placeholder: "0",
      },
    },
  ],
};

export const TOPIC_SURVEY = [
  PhysicalActivity,
  {
    title: "Health eating",
    desc: "The aim of these question is to obtain basic clues about your overall diet",
    icon: food,
    target: 1,
    steps: [
      { id: faker.random.alphaNumeric(8), label: "Fruit - vegetables" },
      { id: faker.random.alphaNumeric(8), label: "Snack or liquids" },
      { id: faker.random.alphaNumeric(8), label: "Water drinking" },
      { id: faker.random.alphaNumeric(8), label: "Diet foods" },
      { id: faker.random.alphaNumeric(8), label: "Describe yourself" },
      { id: faker.random.alphaNumeric(8), label: "Eating at work" },
    ],
  },
  {
    title: "Mental wellbeing",
    desc: "Psychological safety is an integral part of the culture, morale and productivity in your workplace, because it determines how you deal with and enjoy daily life! So the goal of these next questions is to obtain basic signals about psychological safety in the work environment, and the challenges that you face in carrying out your work tasks.",
    icon: mental,
    target: 2,
    steps: [
      { id: faker.random.alphaNumeric(8), label: "Mental wellbeing" },
      { id: faker.random.alphaNumeric(8), label: "Job demands" },
      { id: faker.random.alphaNumeric(8), label: "Relationships" },
      { id: faker.random.alphaNumeric(8), label: "Job role" },
      { id: faker.random.alphaNumeric(8), label: "Financial awareness" },
    ],
  },
  {
    title: "Personal Information",
    desc: "Here we need to get some Personal Information.",
    icon: personal,
    target: 3,
    steps: [
      { id: faker.random.alphaNumeric(8), label: "General health" },
      { id: faker.random.alphaNumeric(8), label: "Work Absent" },
      {
        id: faker.random.alphaNumeric(8),
        label: "Medical illnesses",
        type: TYPE_QUESTION.BOOLEAN,
        props: {
          header: "Medical illnesses",
          desc: `Do you suffer from any long-term medical illnesses – for example, diabetes or back pain?`,
        },
      },
      { id: faker.random.alphaNumeric(8), label: "Work hours" },
      { id: faker.random.alphaNumeric(8), label: "Work shifts" },
      { id: faker.random.alphaNumeric(8), label: "Service years" },
      { id: faker.random.alphaNumeric(8), label: "Age" },
      { id: faker.random.alphaNumeric(8), label: "Gender" },
    ],
  },
];
