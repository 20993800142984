import React from "react";
import { FontFamily } from "src/constants/fontFamily";
import { useTranslator } from "src/context/storeTranslator";

export function H1({ className, children, style, noFontFamily }) {
  const [lang] = useTranslator();
  return (
    <h1
      className={"text-xl lg:text-3xl text-bold " + className}
      style={{
        fontFamily: noFontFamily
          ? ""
          : lang === "en"
          ? FontFamily.Ubuntu
          : FontFamily.Airbnb_Cereal,

        ...style,
      }}
    >
      {children}
    </h1>
  );
}
export function P({ className, children, style }) {
  const [lang] = useTranslator();

  const fontFamily =
    lang === "en" ? FontFamily.Ubuntu : FontFamily.Airbnb_Cereal;

  return (
    <span
      className={"  " + className}
      style={{
        fontFamily,
        // color: lang === "en" ? "#f00" : "#0f0",
        ...style,
      }}
    >
      {children}
    </span>
  );
}
