import React from "react";
import { useEffect } from "react";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { useTrackerSurvey } from "src/context/trackSurvey";
import { useLang } from "src/hooks/useLang";
import { useSurveyTrackingDetails } from "src/hooks/useSurveyTrackingDetails";

function SwitchQuestions({ answer, current, setError }) {
  let { step, setStep } = useTrackerSurvey();
  const { lang } = useLang();
  const errorMessage = {
    ar: ".من فضلك اختر إجابة أولاً",
    en: "Please choose the answer first.",
  };
  const {
    IS_FIRST_QUESTION,
    IS_FIRST_SECTION,
    IS_LAST_QUESTION,
    IS_LAST_SECTION,
    onPrevSection,
    onNextSection,
  } = useSurveyTrackingDetails();

  useEffect(() => {
    const btnUp = document.querySelector(`#btn-up`);
    const btnDown = document.querySelector(`#btn-down`);
    document.onkeydown = function (evt) {
      const key = evt.key;
      if (key === "ArrowUp") btnUp.click();
      else if (key === "ArrowDown") btnDown.click();
    };
  }, []);
  return (
    <div
      id="switch-questions"
      className={`flex fixed lg:absolute   bottom-20  lg:bottom-0  px-3 lg:px-3 lg:pb-3 `}
    >
      <div className="flex flex-col  relative z-30    ">
        <ButtonSwitch
          id={"btn-up"}
          disabled={IS_FIRST_QUESTION && IS_FIRST_SECTION}
          onClick={() => {
            if (step === 0) {
              onPrevSection();
            } else {
              setStep(--step);
              setError("");
            }
          }}
        >
          <AiOutlineCaretUp />
        </ButtonSwitch>
        <div className="h-2" />
        {current?._id !== 83 ? (
          <ButtonSwitch
            id={"btn-down"}
            disabled={IS_LAST_QUESTION && IS_LAST_SECTION}
            onClick={() => {
              if (IS_LAST_QUESTION && answer[current._id]) {
                onNextSection();
              } else if (!IS_LAST_QUESTION && answer[current._id]) {
                setStep(++step);
              } else {
                setError(errorMessage[lang]);
              }
            }}
          >
            <AiOutlineCaretDown />
          </ButtonSwitch>
        ) : null}
      </div>
    </div>
  );
}
const ButtonSwitch = ({ disabled, children, onClick, id }) => (
  <button
    id={id}
    disabled={disabled}
    onClick={onClick}
    className={`w-8 h-8 rounded flex justify-center items-center bg-theme-primary   text-white ${
      disabled
        ? "bg-gray-500 cursor-not-allowed"
        : "bg-theme-primary cursor-pointer hover:bg-theme-primary-dark"
    } `}
  >
    {children}
  </button>
);
export default SwitchQuestions;
