import React from "react";
import CompletePage from "src/components/pages/CompletePage";
import SurveyIntro from "src/components/pages/SurveyIntro";
import SurveyDashboard from "src/components/template/SurveyDashboard";
import { Route, Switch } from "react-router-dom";
import { SurveyProvider, useSurveyState } from "src/hooks/useSurvey";
import { useStateLocal } from "src/hooks/useLocalState";
import { TranslatorStore } from "src/context/storeTranslator";
import "../style/survey.css";

import Survey404 from "src/components/pages/Survey404";
import { getCompanyNameFromSurveyURL } from "src/helper/navigation";
import IntroSection from "src/components/pages/IntroSection";
import { useEffect } from "react";
// import SurveyNotFound from "src/components/pages/SurveyNotFound";
// import { Redirect } from "react-router-dom/cjs/react-router-dom";

function SurveyNavigation() {
  const [
    answer,
    setAnswers,
    data,
    loading,
    _id,
    onRefresh,
    companyInfo,
    onSubmitSurvey,
  ] = useSurveyState();

  const [lang, setLang] = useStateLocal("en", "lang");


  let name = getCompanyNameFromSurveyURL();
  return (
    <SurveyProvider.Provider
      value={{
        answer,
        setAnswers,
        data,
        loading,
        _id,
        onRefresh,
        companyInfo,
        onSubmitSurvey,
      }}
    >
      <TranslatorStore.Provider value={[lang, setLang]}>
        <div
          dir={lang === "ar" ? "rtl" : "ltr"}
          className={lang === "ar" ? "rtl" : "ltr"}
        >
          <Switch>
            <Route
              exact
              path={`/survey/404`}
              name="Form Page"
              render={(props) => <Survey404 {...props} />}
            />

            {/* <Route
              exact
              path={`/survey/notfound`}
              name="Not Found"
              render={(props) => <SurveyNotFound {...props} />}
            /> */}

            <Route
              exact
              path={`/survey/${name}`}
              name="Intro Survey"
              render={(props) => <SurveyIntro name={name} {...props} />}
            />
            <Route
              exact
              path={`/survey/${name}/complete-survey`}
              name="Complete Survey"
              render={(props) => <CompletePage {...props} />}
            />
            {/* {data.map((route) => ( */}
            <Route
              exact
              path={`/survey/${name}/:sectionID/intro`}
              name="Intro Section"
              // redirect="/survey/notfound"
              // render={(props) => <Redirect to="/survey/notfound" />}
              render={(props) => <IntroSection {...props} />}
            />
            <Route
              exact
              path={`/survey/${name}/:sectionID`}
              name="Form Page"
              // redirect="/survey/notfound"
              render={(props) => <SurveyDashboard {...props} />}
            />
            {/* ))} */}
          </Switch>
        </div>
      </TranslatorStore.Provider>
    </SurveyProvider.Provider>
  );
}

export default SurveyNavigation;
