import React from "react";
import { FaSpinner } from "react-icons/fa";

import { useTranslator } from "src/context/storeTranslator";
import Row from "./Row";
import { P } from "./Text";

function Button({
  children,
  onClick = () => {},
  className,
  style,
  outline = false,
  noFill,
  loading,
  disabled,
  icon,
  secondary,
  iconRight,
  classNameText,
  id,
}) {
  const [translate] = useTranslator();
  return (
    <div
      id={id}
      onClick={() => !disabled && onClick()}
      className={`${
        translate === "ar" ? "flex-row-reverse" : ""
      }  px-4 py-2 border-theme-primary text-center ${
        outline
          ? "text-theme-primary border-2 hover:bg-gray-50 shadow-2xl "
          : noFill
          ? "hover:bg-gray-100 text-gray-500"
          : disabled
          ? "bg-[#A1B0D4] text-gray-100 cursor-not-allowed "
          : secondary
          ? "bg-white  border-2 border-gray-200 text-gray-700  "
          : " text-white bg-theme-primary hover:bg-opacity-90 border-2 lg:shadow-md shadow-theme-primary"
      } rounded-xl  ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      }  ${className}   flex items-center`}
      // style={{ ...style }}
      style={{ ...style }}
    >
      <Row className={"w-full items-center"}>
        {loading ? (
          <FaSpinner className="animate-spin ml-2" />
        ) : icon ? (
          <div className="ml-2">{icon}</div>
        ) : (
          ""
        )}
        <P
          className={"flex-1 text-center text-sm " + classNameText}
          noFontFamily
        >
          {children}
        </P>
        {iconRight && <div className="ml-2 ">{iconRight}</div>}
      </Row>
    </div>
  );
}

export default Button;
