import React from "react";
import { FaCheck } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useTranslator } from "src/context/storeTranslator";
import { useTrackerSurvey } from "src/context/trackSurvey";
import { getCompanyNameFromSurveyURL } from "src/helper/navigation";
import useSurvey, { convertAnswerToBoolean } from "src/hooks/useSurvey";
import { P } from "./Text";

function StepItem({ select, data, index, sectionId, sm }) {
  const { answer } = useSurvey();
  const isChecked = convertAnswerToBoolean(answer[data._id]);
  const [translate] = useTranslator();
  const { setStep, target, setTarget } = useTrackerSurvey();
  const isTarget = sectionId === target;
  const isSelected = select?._id === data._id && isTarget;
  const companyName = getCompanyNameFromSurveyURL();
  const history = useHistory();
  return (
    <div className="px-4 flex items-end relative w-full ">
      <div
        className={`${
          sm ? "h-14" : "h-6"
        } w-[1px] bg-gray-300 justify-center items-center flex  relative`}
      >
        <div
          className={`${isChecked ? "h-4 w-4" : "w-3 h-3"} rounded-full ${
            isSelected || isChecked
              ? "bg-theme-logo "
              : "bg-white border-gray-300 border-2"
          }  absolute bottom-0 flex justify-center items-center transition-all duration-300`}
        >
          <FaCheck
            className={`${isChecked ? "" : "hidden"} text-xs text-white`}
            style={{ fontSize: "8px" }}
          />
        </div>
      </div>
      <div className="h-3  flex justify-center items-center">
        <div className="w-5 h-[1px] bg-gray-300 "></div>
      </div>
      {/* <div>dsads</div> */}
      <div
        onClick={() => {
          setStep(index);
          if (!isTarget) {
            setTarget(sectionId);
            history.push(`/survey/${companyName}/${sectionId}`);
          }
        }}
        className="flex cursor-pointer items-center justify-between text-xs flex-1 pl-3"
      >
        <div>
          <P
            className={`${
              isSelected
                ? "text-theme-logo "
                : isChecked
                ? "text-black "
                : "text-gray-400  " + " transition-all duration-200"
            } text-[14px] font-bold `}
          >
            {data.title[translate]}
          </P>
        </div>
        {!sm && <P className="text-gray-400">{index + 1}</P>}
      </div>
    </div>
  );
}

export default StepItem;
