import { useContext } from "react";

const { createContext } = require("react");

export const UserProvider = createContext({
  user: {
    email: "",
    name: "",
    type: "",
    _id: "",
    company: "",
  },
  setUser: () => {},
});
export const useUser = () => useContext(UserProvider);
