import React from "react";
import { P } from "../atoms/Text";

import useSurveyLabel from "src/hooks/useSurveyLabel";
function LoadingSurvey() {
  const label = useSurveyLabel();
  return (
    <div className="w-screen h-screen fixed z-50 flex justify-center items-center bg-white flex-col">
      <div className="w-64 h-64 relative mb-10 flex justify-center items-center">
        <div className="absolute w-[65%] h-[65%] bg-theme-logo rounded-full animate-ping" />
        {/* <div className="absolute w-full h-full bg-white rounded-full  z-10" /> */}
        <img
          alt="logo-labayh"
          src={'/logo.svg'}
          className="w-full  relative z-20  rounded-full  bg-white "
        />
      </div>
      <P className={"text-3xl font-bold text-center whitespace-pre-line"}>
        {label["loading_survey"]}
      </P>
      <div className="text-theme-logo bg-theme-logo bg-opacity-10  p-2 text-xs mt-4 rounded-full">
        {label["demo"]}
      </div>
    </div>
  );
}

export default LoadingSurvey;
