import { useEffect, useState } from "react";
import { useLabayhContext } from "src/context/LabayhAdmin";
import labayhAPI from "../api/labayhAPI";
import {DOMAIN_URL} from "../constants/URL"

export function useCompanyInfo() {
  const [data, setData] = useState({ name: "", logo: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const {
    state: { rname },
  } = useLabayhContext();
  const URL_SURVEY = `${DOMAIN_URL}/survey/${rname}`;

  async function onRefresh() {
    try {
      setError(false);
      setLoading(true);
      const companyName = window.location.pathname;
      if(companyName.split('/').length===0)
        return;
      const slug = companyName.split("/")[1];
      const { data } = await labayhAPI.get(
        `/api/company-admin/get-logo/${slug}`
      );

      setData({
        ...data,
        logo: `${process.env.REACT_APP_ASSETS}/${data.logo}`,
      });
      setIsComplete(true);
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    onRefresh();
  }, []);
  return { data, loading, URL_SURVEY, error, isComplete };
}
